.images-with-captions {
   display: flex;
   justify-content: center;
   width: 100%;

   .content-wrapper {
      @extend .container;
      @extend .padding-top-auto;
      @extend .padding-bottom-auto;
      display: flex;
      flex-direction: column;
      margin-top: 45px;

      .content-heading {
         width: 100%;
         margin-bottom: 25px;
         font-size: 34px;

         @media screen and (max-width: 767px) {
           margin-bottom: 20px;
         }
      }

      .all-blocks {
         width: 100%;
      }
   }

   &.bg-color-blueDark {

      .point-number {
         color: white;
      }

      .lead-18 {
         color: white !important;
      }
   }

   &.bg-color-dark {

      .point-number {
         color: white;
         border-color: white !important;
      }

      .lead-18 {
         color: white !important;
      }
   }
}

.block-image-with-caption {
   width: 100%;
   margin-bottom: 45px;

   &.is-visible {

      .images-container {
         cursor: auto;

         .background-opcatity {
            display: none;
         }

         .image-preview {
            display: none;
         }

         .image-with-points {
            display: block;
         }
      }

      .block-content {
         display: flex;
         max-height: 100%;
      }
   }

   .images-container {
      position: relative;
      border: 1px solid #d2d2d2;
      cursor: pointer;

      .background-opcatity {
         position: absolute;
         display: flex;
         align-items: center;
         justify-content: center;
         inset: 0;
         width: 100%;
         height: 100%;
         background-color: rgba(255,255,255,0.5);
         z-index: 3;

         .svg-container {
            background-color: $tertiaryColor;
            height: 70px;
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            @media screen and (max-width: 767px) {
               height: 42px;
               width: 42px;

               svg {
                  height: 19px;
                  width: 19px;
               }
            }
         }
      }

      .image-preview {
         display: block;
      }

      .image-with-points {
         display: none;
      }

      img {
         width: 100%;
         height: auto;
      }

      &:hover {

         .background-opcatity {
            background-color: rgba(255,255,255,0);
            border: 4px solid $primaryColor;

            .svg-container {
               background-color: $primaryColor;
            }
         }
      }
   }

   .block-content {
      display: none;
      flex-direction: row;
      width: 100%;
      gap: 50px;
      row-gap: 40px;
      padding: 45px 0 0;
      max-height: 0;
      flex-wrap: wrap;
      //transition: all 25s ease;
      overflow: hidden;

      .point-container {
         width: calc(50% - 25px);
         @media screen and (max-width: 767px) {
            width: 100%;
         }

         .row-heading {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            gap: 10px;
            align-items: center;

            .point-number {
               height: 38px;
               min-width: 38px;
               display: flex;
               justify-content: center;
               align-items: center;
               border: 4px solid $tertiaryColor;
               border-radius: 50%;
               font-size: 24px;
            }

            .lead-18 {
               line-height: 25px;
               font-size: 20px;
               margin: 0;
               color: $primaryColor;
            }
         }
         
         .point-description {
            margin-left: 48px;
         }
      }
   }
}
