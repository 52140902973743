.drawers {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &.bg-color-dark {
    .content-wrapper .accordion-items .accordion-item .accordion-heading {
      color: $light;

      &:after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAyOCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42MDkgMjAuMDcyYTIuMzMxIDIuMzMxIDAgMCAxLTEuNjY0LS42OTlMNC42NyAxMS45NzFhMi4zMzMgMi4zMzMgMCAwIDEgMy4zMjctMy4yNzJsNS42MTIgNS43MSA1LjYxLTUuNzFhMi4zMyAyLjMzIDAgMCAxIDMuMy0uMDI4Yy45Mi45MDIuOTM0IDIuMzguMDMgMy4zbC03LjI3NiA3LjQwMmEyLjMzMyAyLjMzMyAwIDAgMS0xLjY2NC42OTkiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
      }
    }
  }

  .content-wrapper {
    @extend .container;
    @extend .padding-top-auto;
    @extend .padding-bottom-auto;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .accordion-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-top: solid 1px #d0d3d4;

      .accordion-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: solid 1px #d0d3d4;
        transition: padding 0.25s ease-in-out;

        &.is-opened {
          padding-bottom: 40px;

          .accordion-heading::after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }

        .accordion-heading {
          position: relative;
          @extend .lead26;
          color: $secondaryColor;
          cursor: pointer;
          margin: 0;
          padding: 20px 70px 20px 0;
          display: flex;
          align-items: center;

          @media screen and (max-width: 767px) {
            font-size: 20px;
            padding: 20px 50px 20px 0;
          }

          .number {
            @extend h1;
            margin: 0;
          }

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 28px;
            height: 28px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAyOCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42MDkgMjAuMDcyYTIuMzMxIDIuMzMxIDAgMCAxLTEuNjY0LS42OTlMNC42NyAxMS45NzFhMi4zMzMgMi4zMzMgMCAwIDEgMy4zMjctMy4yNzJsNS42MTIgNS43MSA1LjYxLTUuNzFhMi4zMyAyLjMzIDAgMCAxIDMuMy0uMDI4Yy45Mi45MDIuOTM0IDIuMzguMDMgMy4zbC03LjI3NiA3LjQwMmEyLjMzMyAyLjMzMyAwIDAgMS0xLjY2NC42OTkiIGZpbGw9IiMwMDQ3QkIiLz48L3N2Zz4=");
            pointer-events: none;
            transition: transform 0.25s ease-in-out;

            @media screen and (max-width: 767px) {
              right: 0;
            }
          }
        }

        .accordion-content {
          width: 100%;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.25s ease-in-out;
          will-change: max-height;
          display: flex;
          flex-direction: column;
          gap: 40px;

          @media screen and (max-width: 1023px) {
            padding: 0;
          }

          & > section {
            &.bg-color-auto {
              background-color: transparent;
              padding: 0;

              .content-wrapper {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
