.blog-hero {
  font-family: Arial, Helvetica, sans-serif;
  padding: 25px 0 50px;

  @media screen and (min-width: 768px) {
    padding: 65px 0;
  }

  .container {
    width: 100%;
    max-width: calc(1175px + 2 * 40px);
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (min-width: 768px) {
      padding: 0 40px;
    }

    .blog-slider {
      .slide {
        display: grid;
        width: 100%;
        margin-right: 40px;

        @media screen and (min-width: 768px) {
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        &-image {
          grid-column: span 3 / span 3;
          width: 100%;
          padding-bottom: 52%;
          position: relative;
          overflow: hidden;
          border-radius: 8px 8px 0px 0px;

          @media screen and (min-width: 768px) {
            border-radius: 8px 0px 0px 8px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            transition: all ease-in-out 0.7s;
          }
        }

        &:hover .slide-image img {
          transform: scale(1.1);
        }

        &-content {
          grid-column: span 2 / span 2;
          padding: 16px 20px 23px;
          border-style: solid;
          border-color: #93989E;
          border-width: 0px 0.5px 0.5px 0.5px;
          border-radius: 0px 0px 8px 8px;
          overflow: hidden;

          @media screen and (min-width: 768px) {
            padding: 30px 30px 20px;
            border-width: 0.5px 0.5px 0.5px 0px;
            border-radius: 0px 8px 8px 0px;
          }
        }

        &-thema {
          font-size: 16px;
          font-weight: 700;
          color: $secondaryColor;
          line-height: 19px;
        }

        &-title {
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          margin: 26px 0 0 0;
          letter-spacing: -0.96px;
          color: $tertiaryColor;
          text-shadow: 0.95px 0.95px 0.95px rgba(0, 0, 0, 0.004);

          @media screen and (min-width: 768px) {
            font-size: 26px;
            line-height: 30px;
            margin: 16px 0 0 0;
          }
        }

        &-desc {
          font-size: 15px;
          line-height: 24px;
          margin: 16px 0 0 0;
          color: $tertiaryColor;
          text-shadow: 0.95px 0.95px 0.95px rgba(0, 0, 0, 0.004);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;

          @media screen and (min-width: 768px) {
            font-size: 16px;
            -webkit-line-clamp: 2;
          }
        }

        .blog-card_desc {
          margin-top: 32px;

          @media screen and (min-width: 768px) {
            margin-top: 20px;
          }
        }
      }

      .flickity-page-dots {

        @media screen and (min-width: 768px) {
          bottom: 20px;
          left: 20px;
          text-align: left;
        }
      }

      .flickity-page-dots .dot {
        width: 10px;
        height: 10px;
        margin: 0 4px 0 0;
        background: $bgLighterBlue;
        opacity: 1;

        @media screen and (min-width: 768px) {
          width: 13px;
          height: 13px;
          margin: 0 5px 0 0;
        }
      }

      .flickity-page-dots .dot.is-selected {
        background: $tertiaryColor;
      }

      
      .flickity-button {
        display: none;
        z-index: 2;

        @media screen and (min-width: 768px) {
          display: block;
          width: 35px;
          height: 35px;
          background: $tertiaryColor;
          transform: translate(0);
          top: unset;
          bottom: 15px;
  
          &.flickity-prev-next-button.next {
            right: unset;
            left: calc(60% - 45px);
          }
  
          &.flickity-prev-next-button.previous {
            left: calc(60% - 90px);
          }

          .flickity-button-icon {
            fill: #fff;
          }
        }
      } 
    }
  }
}