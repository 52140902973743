.block-faq {
   width: 100%;
   display: flex;
   justify-content: center;
   
   .content-wrapper {
      @extend .container;
      display: flex;
      flex-direction: column;
      
      .content-heading {
         width: 100%;
         margin-bottom: 40px;
         color: $primaryColor;
         @media screen and (max-width: 767px) {
            margin-bottom: 20px;
         }
      }

      .content-container {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         width: 100%;
         gap: 40px;
         @media screen and (max-width: 1023px) {
            flex-direction: column-reverse;
         }

         .accordion-items {
            width: 75%;
            @media screen and (max-width: 1023px) {
               width: 100%;
            }

            .accordion-item {
               display: none;
               flex-direction: column;
               border-bottom: solid 1px #d0d3d4;
               transition: padding 0.25s ease-in-out;
               animation: fadeOut .5s ease;
               opacity: 0;
   
               &.is-opened {
                  padding-bottom: 40px;
                  .accordion-heading svg{
                     transform: rotate(-180deg);
                  }
               }
   
               &.is-visible {
                  display: flex;
                  animation: fadeIn .5s ease;
                  animation-iteration-count: 1;
                  animation-fill-mode: forwards;
               }
   
               .accordion-heading {
                  @extend .lead26;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  color: $primaryColor;
                  cursor: pointer;
                  margin: 0;
                  padding: 20px 0;
                  @media screen and (max-width: 767px) {
                     padding: 20px 0;
                  }

                  p {
                     margin: 0;
                  }
               }
   
               .accordion-content {
                  padding: 0 40px;
                  width: 100%;
                  max-width: 1000px;
                  max-height: 0;
                  overflow: hidden;
                  transition: max-height 0.25s ease-in-out;
                  will-change: max-height;
                  @media screen and (max-width: 1023px) {
                     padding: 0;
                  }
               }
            }
         }

         .faq-filters {
            display: flex;
            flex-direction: column;
            list-style-type: none;
            width: 25%;
            padding: 0;
            margin: 0;
            @media screen and (max-width: 1023px) {
               flex-direction: row;
               flex-wrap: wrap;
               width: 100%;
               justify-content: space-between;
            }

            li {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: center;
               border: solid 1px #d0d3d4;
               padding: 15px 20px;
               margin-top: -1px;
               gap: 20px;
               @media screen and (max-width: 1023px) {
                  width: 100%;
                  max-width: calc(50% - 15px);
               }

               @media screen and (max-width: 600px) {
                  width: 100%;
                  max-width: none;
               }

               &:hover {
                  background-color: $primaryColor;
                  border: 1px solid $primaryColor;
                  cursor: pointer;

                  p {
                     background-color: #fff;
                  }

                  a {
                     color: white;
                  }
               }

               &.is-active {
                  background-color: $primaryColor;
                  border: 1px solid $primaryColor;

                  p {
                     background-color: #fff;
                  }

                  a {
                     color: white;
                  }
               }

               p {
                  display: block;
                  margin: 0;
                  color: #002855;
                  background-color: #daeef4;
                  padding: 6px 11px;
                  border-radius: 12px;
                  line-height: 1;
                  height: 26px;
               }

               a {
                  color: $primaryColor;
                  text-decoration: none;
               }
            }
         }
      }
   }

}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@keyframes fadeOut {
   0% {
      opacity: 1;
   }
   
   100% {
      opacity: 0;
   }
}

 