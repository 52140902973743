// Titles
h1 {
  font-family: $fontMerkury;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  margin: 0 0 20px;

  @media screen and (max-width: 767px) {
    font-size: 30px;
    margin-bottom: 10px;
  }
}

h2 {
  font-family: $fontMerkury;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  margin: 0 0 20px;

  @media screen and (max-width: 767px) {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

h3 {
  font-family: $fontMerkury;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  margin: 0 0 15px;

  @media screen and (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

h4 {
  font-family: $fontMerkury;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0 0 15px;

  @media screen and (max-width: 767px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

// Texts
.lead26 {
  font-family: $fontMerkury;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.12px;
  margin: 0 0 10px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.lead18 {
  font-family: $fontMerkury;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.09px;
  margin: 0 0 10px;

  @media screen and (max-width: 767px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.body15 {
  font-family: $fontMerkury;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  margin: 0 0 10px;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.regularText {
  font-family: $fontArial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  margin: 0 0 10px;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.caption {
  font-family: $fontArial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  margin: 0 0 5px;

  @media screen and (max-width: 767px) {
    font-size: 10px;
    margin-bottom: 3px;
  }
}
