.iframe-block {
  width: 100%;
  display: flex;
  justify-content: center;
  @extend .padding-top-auto;
  @extend .padding-bottom-auto;

  .iframe-wrapper {
    width: 100%;

    &.is-contained {
      @extend .container;
    }

    iframe {
      width: 100%;
    }
  }
}
