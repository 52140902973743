:root {
   .fui-i {
      font-family: $fontMerkury;
   }

   .fui-alert {
      --fui-alert-font-size: 14px;
      --fui-alert-padding: 10px 16px;
      --fui-alert-line-height: 20px;
   }

   .fui-input,
   .fui-select {
      --fui-input-font-size: 16px;
      --fui-input-padding: 11px 16px;
      --fui-input-border-radius: 3px;
   }

   .fui-label {
      --fui-label-font-size: 16px;
      color: $tertiaryColor; 
      margin: 20px 0 7px;
   }

   .fui-row {
      @media screen and (max-width: 767px) {
         flex-direction: column;
      }
   }

   .fui-legend {
      display: none;
   }

   .fui-required {
      --fui-label-font-size: 16px;
      color: $grey; 
   }

   .fui-checkbox label, .fui-radio label {
      font-size: 13px;
      --fui-check-label-padding-left: 25px;
      --fui-check-label-line-height: 20px
   }

   .fui-checkbox input:checked + label::before {
      border: 2px solid $tertiaryColor;
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M19.419 5.39a1.56 1.56 0 0 0-2.266.218L10.04 14.53l-3.616-2.52c-.764-.472-1.75-.208-2.201.591-.417.737-.237 1.684.417 2.198L10.668 19l8.96-11.241c.568-.713.476-1.773-.206-2.367l-.003-.003z' id='3u9zl2msea'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cuse fill='%23002855' xlink:href='%233u9zl2msea'/%3E%3C/g%3E%3C/svg%3E");
      background-size: 16px;
   }

   .fui-radio input:checked + label::before {
      border: 2px solid $primaryColor;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23009fdf'/%3E%3C/svg%3E");
      background-size: 12px;
      background-color: transparent;
   }

   .fui-radio label::before {
      --fui-border-color: $primaryColor;
      border: 2px solid $primaryColor;
   }

   .fui-checkbox label::before {
      --fui-border-color: $tertiaryColor;
      border: 2px solid $tertiaryColor;
   }

   .fui-checkbox label::before, .fui-radio label::before {
      --fui-check-label-top: 0;
      --fui-check-label-width: 20px;
      --fui-check-label-height: 20px;
      --fui-check-check-border-radius: 6px;
   }

   .fui-submit, 
   .fui-btn {
      @extend .button;
      // background-color: $tertiaryColor;
      border: none;
   }

   // .fui-submit:hover, 
   // .fui-btn:hover {
   //    background-color: $primaryColor;
   //    border-color: $primaryColor;
   // }
}

.block-form-container {
   width: 100%;
   display: flex;
   justify-content: center;
   margin: 50px 0;
   
   .content-wrapper {
      @extend .container;
      display: flex;
      flex-direction: column;
      
      .content-heading {
         width: 100%;
         margin-bottom: 40px;
         color: $primaryColor;
         @media screen and (max-width: 767px) {
            margin-bottom: 20px;
         }
      }
   }
}

.fui-alert {
   font-size: 14px;
   line-height: 20px;
   padding: 10px 20px;
}

.fui-row .fui-field-container {
   position: relative;
   border: 1px solid $grey;
   border-radius: 3px;

   &:has(.fui-checkbox), &:has(.fui-heading) {
      border: none;
   }

   .fui-heading {
      margin: 20px 0 0;
   }

   .label-move {
      color: $grey;
      position: absolute;
      left: 20px;
      top: 25px;
      transform: translateY(-50%);
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      opacity: 1;
      pointer-events: none;
      transition: all ease-out .2s;
   }

   .label-move:has(+ .input-move:focus), 
   .label-move:has(+ .input-move:not(:placeholder-shown)), 
   .label-move:has(+ input[type="hidden"]:not([value=""])) {
      top: 10px;
      transform: translateY(0);
      font-size: 10px;
      color: $tertiaryColor;
      font-weight: bold;
   }

   .input-move {
      width: 100%;
      appearance: none;
      border: 0;
      background: white;
      border-radius: 3px;
      padding: 25px 20px 8px 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      outline: 0;
      transition: all ease-out .2s;
      &:not(:placeholder-shown), &:focus {
         & ~ .label-move {
            top: 10px;
            transform: translateY(0);
            font-size: 10px;
            color: $tertiaryColor;
            font-weight: bold;
         }
      }
   }

   /*.input-move:focus + .label-move, .input-move:not(:placeholder-shown) + .label-move {
      top: 10px;
      transform: translateY(0);
      font-size: 10px;
      color: $tertiaryColor;
      font-weight: bold;
   }*/

   input {
      width: 100%;
      appearance: none;
      border: 0;
      background: white;
      border-radius: 3px;
      padding: 25px 20px 8px 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      outline: 0;
      transition: all ease-out .2s;
      &:not(:placeholder-shown), &:focus {
         & + label {
            top: 10px;
            transform: translateY(0);
            font-size: 10px;
            color: $tertiaryColor;
            font-weight: bold;
         }
      }
   }

}
