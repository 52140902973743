.blog-card {

  &:last-child {
    display: none;

    @media screen and (min-width: 600px) {
      display: block;
    }
  }

  &_img {
    display: block;
    width: 100%;
    padding-bottom: 66.5%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      margin-bottom: 30px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      transition: all ease-in-out 0.7s;
    }
  }

  &:hover img {
    transform: scale(1.3);
  }

  &_title {
    font-size: 22px;
    font-weight: 700;
    line-height: 113%;
    letter-spacing: -0.96px;
    color: $tertiaryColor !important;
    margin-bottom: 20px;
    display: inline-block;

    @media screen and (min-width: 768px) {
      margin-bottom: 24px;
    }
  }

  &_desc a,
  &_desc p,
  &_desc span {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
  }

  &_desc a {
    color: $secondaryColor;
  }

  .point {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #71757E;
    margin: 0 4px 2px;
  }
}
