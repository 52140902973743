// --------------------------------------------------
// Import Fonts
// --------------------------------------------------

@font-face {
  font-family: 'Merkury';
  src: url('/fonts/merkury_bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
