.blog-popular {
  padding-bottom: 120px;

  @media screen and (min-width: 768px) {
    padding-bottom: 70px;
  }

  .container {
    width: 100%;
    max-width: calc(1175px + 2 * 40px);
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
      padding: 0 40px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: -0.9275px;
    color: $secondaryColor;
    margin-bottom: 25px;

    @media screen and (min-width: 768px) {
      font-size: 32px;
    }
  }

  &__body {
    display: grid;
    gap: 74px;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 30px;
    }
  }
}