.promo-content-double {
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    @extend .container;
    @extend .padding-top-auto;
    @extend .padding-bottom-auto;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .content-boxes {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
      }

      .content-box {
        width: calc(50% - 20px);
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        @media screen and (max-width: 1023px) {
          width: calc(50% - 10px);
          padding: 20px;
          gap: 20px;
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .box-top {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;

          .top-heading {
            @extend .lead26;
            margin: 0;
            text-align: center;
          }

          .top-text {
            font-family: $fontMerkury;
            font-size: 18px;
            font-weight: 700;
            margin: 0;
            text-align: center;
          }
        }

        .box-bottom {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .bottom-text {
            font-family: $fontMerkury;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.2;
            margin: 0;
            text-align: center;
          }

          .bottom-heading {
            font-size: 64px;
            line-height: 1.1;
            margin: 0;
            text-align: center;

            @media screen and (max-width: 1023px) {
              font-size: 38px;
            }
          }
        }
      }
    }
  }
}
