.breadcrumb {
  @extend .container;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  height: 20px;

  @media screen and (max-width: 767px) {
    display: none;
  }

  &.color-light {
    .breadcrumb-item {
      &:not(:last-of-type):after, a, span {
        color: $light;
      }
    }
  }

  .breadcrumb-item {
    display: flex;
    align-items: center;

    &:not(:last-of-type):after {
      content: '>';
      margin-left: 10px;
      font-family: Arial;
      font-size: 12px;
      font-weight: 300;
      color: $dark;
    }

    a {
      font-family: Arial;
      font-size: 12px;
      font-weight: 300;
      color: $dark;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      font-family: Arial;
      font-size: 12px;
      font-weight: 300;
      color: $dark;
    }
  }
}

.breadcrumb-blog {
  @media screen and (max-width: 767px) {
    display: none;
  }

  .container {
    @extend .container;
    display: flex;
    justify-content: space-between;

    .breadcrumb {
      padding-left: 24px;
      width: 70%;
      margin: 0;
      justify-content: unset;
      
      .breadcrumb-item a,
      .breadcrumb-item span {
        color: $blogGrey;
      }

      .breadcrumb-item:not(:last-of-type):after {
        content: '▶';
        color: $blogGrey;
        font-size: 8px;
      }
    }

    .active-buttons {
      padding-right: 24px;
      flex-shrink: 0;
      display: flex;
      gap: 13px;

      a, span {
        font-size: 12px;
        color: $blogGrey;
      }

      a {
        display: flex;
        align-items: center;

        svg {
          margin-right: 6px;
        }
      }
    }
  }
}