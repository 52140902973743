.content-quintuple {
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .content-columns {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      // @media screen and (max-width: 520px) {
      //   flex-direction: column;
      //   gap: 20px;
      // }

      .content-column {
        width: calc((100% - 100px) / 5);

        @media screen and (max-width: 1023px) {
          width: calc((100% - 40px) / 3);
          margin-bottom: 20px;
        }

        @media screen and (max-width: 767px) {
          width: calc((100% - 40px) / 2);
        }

        img {
          width: 85px;
          height: 65px;
        }

        figure > iframe {
          width: 100% !important;
        }
      }
    }
  }
}
