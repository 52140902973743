.gas-prices {
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 767px) {
      gap: 20px;
    }

    .content-heading {
      width: 100%;
    }

    .prices-table-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-bottom: solid 1px #d0d3d4;

      .first-heading {
        min-width: 403px;
        padding: 16px 20px;
        background-color: transparent;
        font-family: $fontMerkury;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: $dark;
        border-top: solid 2px rgba(0, 0, 0, 0.85);
        border-right: solid 2px rgba(0, 0, 0, 0.85);
        border-left: solid 2px rgba(0, 0, 0, 0.85);

        @media screen and (max-width: 1023px) {
          min-width: 303px;
        }

        @media screen and (max-width: 767px) {
          min-width: auto;
          width: calc(50% + 1px);
          font-size: 13px;
          padding: 10px;
          border-width: 1px;
        }
      }

      .table-row {
        width: 100%;
        display: flex;
        align-items: stretch;

        .heading-cell {
          min-width: 200.5px;
          padding: 16px 20px;
          background-color: #505d6f;
          font-family: $fontArial;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.23;
          letter-spacing: normal;
          text-align: center;
          color: $light;

          @media screen and (max-width: 1023px) {
            min-width: 150.5px;
          }

          @media screen and (max-width: 767px) {
            min-width: auto;
            width: 25%;
            padding: 10px;

            &:first-child {
              color: #505d6f;
              font-size: 0;

              &:after {
                content: 'Desc.';
                color: $light;
                font-size: 13px;
              }
            }
          }

          &:nth-child(2n+2) {
            border-right: solid 2px rgba(0, 0, 0, 0.85);

            @media screen and (max-width: 767px) {
              border-width: 1px;
            }
          }

          &.large {
            min-width: auto;
            flex: 1;
            text-align: left;
          }
        }

        .label-cell {
          min-width: 200px;
          padding: 16px 20px;
          background-color: transparent;
          font-family: $fontArial;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.23;
          letter-spacing: normal;
          text-align: center;
          color: $dark;
          text-transform: uppercase;

          @media screen and (max-width: 1023px) {
            min-width: 150px;
          }

          @media screen and (max-width: 767px) {
            min-width: auto;
            padding: 10px;
            font-size: 11px;
          }

          &.large {
            min-width: auto;
            flex: 1;
            text-align: left;
            align-self: center;

            @media screen and (max-width: 767px) {
              width: 25%;
            }
          }

          &.grey {
            background-color: rgba(#002855, 0.05);
          }
        }

        .cell-column {
          display: flex;
          flex-direction: column;
          border-left: solid 1px #d0d3d4;

          @media screen and (max-width: 767px) {
            width: 25%;
            border-width: 1px;
          }

          &:nth-child(3) {
            border-left: solid 2px rgba(0, 0, 0, 0.85);

            @media screen and (max-width: 767px) {
              border-width: 1px;
            }
          }

          &:nth-child(4) {
            border-right: solid 2px rgba(0, 0, 0, 0.85);

            @media screen and (max-width: 767px) {
              border-width: 1px;
            }
          }
        }
      }

      .category-cell {
        width: 100%;
        padding: 16px 20px;
        background-color: rgba($primaryColor, 0.45);
        font-family: $fontArial;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: $dark;
        text-transform: uppercase;
        border-right: solid 2px rgba(0, 0, 0, 0.85);

        @media screen and (max-width: 767px) {
          padding: 10px;
          border-width: 1px;
        }
      }
    }
  }
}
