.box-banner {
   position: relative;
   display: flex;
   justify-content: center;
   width: 100%;
   max-width: 1920px;
   margin: 0 auto;
   overflow: hidden;
   min-height: 400px;
   max-height: 645px;
   @media screen and (max-width: 767px) {
      flex-direction: column;
   }

   .image-background-desktop {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      inset: 0;
      @media screen and (max-width: 767px) {
         display: none;
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         display: block
      }
      
   }
   .image-background-mobile {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 350px;
      z-index: -1;
      inset: 0;
      overflow: hidden;

      @media screen and (max-width: 767px) {
         display: block;
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         display: block
      }
      
   }

   .content-wrapper {
      @extend .container;
      display: flex;
      flex-direction: row;
      padding: 60px 40px 50px;
      justify-content: flex-start;
      @media screen and (max-width: 767px) {
         flex-direction: column;
         padding: 0;
      }

      &.alignement-right {
         justify-content: flex-end;
      }

      .content-box {
         display: flex;
         flex-direction: column;
         justify-content: center;
         max-width: 310px;
         padding: 45px 30px 30px;
         @media screen and (max-width: 767px) {
            max-width: none;
            width: 100%;
            border-radius: 0 !important;
            padding: 30px 20px;
            gap: 10px;
         }

         &.bg-color-none {
            padding: 0;
            width: 50%;
            max-width: none;

            &.text-color-ligth {
               
               @media screen and (max-width: 767px) {
                  padding: 30px 20px;
                  width: 100%;

                  p, a {
                     color: $tertiaryColor;
                  }

                  h1, h2, h3, h4, h5, h6 {
                     color: $tertiaryColor;
                  }

                  .button {
                     background-color: $tertiaryColor;
                     color: #fff;
                  }
               }
            }
         }

         &.text-color-ligth {

            p, a {
               color: #fff;
               text-decoration: none;
            }

            h1, h2, h3, h4, h5, h6 {
               color: #fff;
            }

            .button {
               background-color: #fff;
               color: $secondaryColor;
            }
         }

         &.text-color-dark {

            p, a {
               color: $tertiaryColor;
               //text-decoration: none;
            }

            h1, h2, h3, h4, h5, h6 {
               color: $tertiaryColor;
            }

            .button {
               background-color: $tertiaryColor;
               color: #fff;
            }
         }

         &.border-radius-1 {
            border-radius: 6px;
         }

         .button {
            @media screen and (max-width: 767px) {
               width: 100%;
            }
         }
      }
   }
}