.blog-result {
  overflow: hidden;
  .container {
    width: 100%;
    max-width: calc(1175px + 2 * 40px);
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
      padding: 0 40px;
    }
  }

  .blog-search {
    margin-top: 16px;
    padding-bottom: 0;

    @media screen and (min-width: 768px) {
      margin-top: 32px;
    }
  }

  .blog-new-cards {
    @media screen and (max-width: 767px) {
      padding-bottom: 130px;
    }
  }

  .blog-new-cards__title {
    display: none;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: -0.93px;
    color: $secondaryColor;
    margin: 65px 0 25px;
  }

  .blog-popular {
    margin-top: 140px;
    padding-bottom: 145px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .blog-new-cards + .blog-popular {
    margin-top: 28px;
  }
}


