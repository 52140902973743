.logos-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }

  .row-wrapper {
    // position: relative;
    // overflow: hidden;
    @extend .container;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 200%;
    animation: slide 50s linear infinite;
  }

  .row-logo {
    display: inline-block;
    flex-shrink: 0;

    img {
      width: 180px;
      height: 60px;
      object-fit: contain;
      transition: .3s ease-in-out;

      @media screen and (max-width: 979px) {
        width: 160px;
        height: 50px;
      }
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }

  @keyframes slide {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(-50%);
    }
  }
}