.navigation-container {
  @extend .container;
  position: relative;
  margin: 0 auto;
  z-index: 2;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    display: none;
  }

  .navigation {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    border-radius: 60px;
    background-color: $tertiaryColor;
    display: flex;
    align-items: center;

    .navigation-mainlink {
      position: relative;
      z-index: 4;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      align-items: center;

      svg {
        height: 20px;
      }
    }

    .navigation-links {
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: space-between;
      background-color: $secondaryColor;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;

      .navigation-item {
        flex: 1;
        height: 100%;
        display: flex;

        &:last-of-type {
          & > .navigation-link {
            border-top-right-radius: 60px;
            border-bottom-right-radius: 60px;
          }

          & > .navigation-submenu:after {
            background-color: $tertiaryColor;
          }
        }

        &:hover {
          & > .navigation-link {
            background-color: $tertiaryColor;
          }

          & > .navigation-submenu {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);
          }
        }

        .navigation-link {
          position: relative;
          z-index: 4;
          flex: 1;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $light;
          background-color: $secondaryColor;
          transition: background-color 0.2s ease-in-out;

          svg {
            height: 4px;
            margin-left: 8px;
            opacity: .5;
          }
        }

        .navigation-submenu {
          position: absolute;
          opacity: 0;
          left: 0;
          top: 60px;
          width: 100%;
          background-color: $light;
          pointer-events: none;
          padding: 5px;
          border-radius: 0 0 30px 30px;
          box-shadow: inset 0px 0px 0px 5px $secondaryColor;
          display: flex;
          align-items: stretch;

          &:before {
            content: '';
            position: absolute;
            top: -30px;
            left: 0;
            width: 30px;
            height: 30px;
            background-color: $tertiaryColor;
          }

          &:after {
            content: '';
            position: absolute;
            top: -30px;
            right: 0;
            width: 30px;
            height: 30px;
            background-color: $secondaryColor;
          }

          .submenu-sublinks {
            width: 25%;
            display: flex;
            flex-direction: column;
            background-color: $bgLighterBlue;
            border-bottom-left-radius: 25px;
            box-shadow: inset 0px 0px 0px 1px $lightGrey;
            overflow-x: hidden;

            .submenu-sublink {
              position: relative;
              width: 100%;
              display: flex;
              padding: 16px 20px;
              font-family: $fontMerkury;
              font-size: 16px;
              line-height: 1.4;
              font-weight: 600;
              font-style: normal;
              border-bottom: 1px solid $lightGrey;
              color: $tertiaryColor;

              &.is-active {
                background-color: $light;

                &:before {
                  content: '';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  width: 1px;
                  right: -1px;
                  background-color: $light;
                }
              }
            }
          }

          .submenu-blocks {
            width: 75%;
            display: flex;

            .navigation-block {
              display: none;

              &.is-active {
                display: flex;
              }
            }
          }

          .navigation-block {
            flex: 1;
            display: flex;
            overflow: hidden;
            position: relative;

            .navigation-content {
              position: relative;
              width: 100%;
              display: flex;
              align-items: stretch;
              border-bottom-right-radius: 25px;
              overflow: hidden;

              &.direction-column {
                flex-direction: column;

                .content-side, .tules-container {
                  width: 100%;
                }

                .tules-container {
                  position: relative;

                  &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    bottom: -1px;
                    background-color: $lightGrey;
                  }

                  .list-item {
                    width: 25%;
                  }
                }
              }

              &.with-image {
                .content-background-image {
                  position: absolute;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  width: 125px;
                  height: 100%;
                  object-fit: cover;
                  object-position: left center;
                }

                .content-side:last-of-type {
                  .content-container {
                    width: 70%;
                  }

                  &.small .content-container {
                    width: 50%;
                  }
                }
              }

              .content-side {
                position: relative;
                width: 50%;
                padding: 40px;

                &.large {
                  width: 60%;
                }

                &.small {
                  width: 40%;
                }

                &:last-of-type {
                  border-left: 1px solid $lightGrey;
                }
              }

              .list-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                &.large {
                  flex-direction: row;
                  flex-wrap: wrap;

                  .list-item {
                    width: calc(50% - 10px);
                  }
                }

                .list-item {
                  font-family: $fontMerkury;
                  font-size: 16px;
                  line-height: 1;
                  font-weight: 700;
                  font-style: normal;
                  color: $tertiaryColor;
                }
              }

              .tules-container {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;

                .list-item {
                  position: relative;
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 10px;
                  padding: 30px 10px;
                  align-self: flex-end;

                  &:hover {
                    .link-icon {
                      opacity: 1;
                    }

                    .link-text {
                      color: $secondaryColor;
                    }
                  }

                  &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    right: -1px;
                    background-color: $lightGrey;
                  }

                  &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                    width: 100%;
                    bottom: -1px;
                    background-color: $lightGrey;
                  }

                  .link-icon {
                    height: 60px;
                    opacity: .7;
                    transition: opacity 0.2s ease-in-out;
                  }

                  .link-text {
                    font-family: $fontMerkury;
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 700;
                    font-style: normal;
                    color: $tertiaryColor;
                    transition: color 0.2s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
