.header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0 30px;
  background-color: $light;

  @media screen and (max-width: 767px) {
    padding: 16px 0;
  }

  .header-container {
    @extend .container;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      align-items: center;
    }

    .left-side {
      display: flex;

      .home-link {
        display: flex;

        .home-link-logo {
          height: 50px;

          @media screen and (max-width: 1023px) {
            height: 40px;
          }

          @media screen and (max-width: 767px) {
            height: 30px;
          }
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 30px;
      margin-bottom: 8px;

      @media screen and (max-width: 1023px) {
        gap: 16px;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }

      .top-line {
        display: flex;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 767px) {
          display: none;
        }

        .nav-link {
          font-family: $fontMerkury;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          color: #797979;

          &:hover:not(.is-button, .language-link) {
            text-decoration: underline;
          }

          &.is-button {
            @extend .button;
            @extend .small;

            &.is-secondary {
              @extend .light;
              border: solid 1px $secondaryColor;
            }
          }

          &.language-link {
            position: relative;
            color: $tertiaryColor;
            transition: color ease-in-out 0.2s;

            &:after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: 2px;
              width: 100%;
              height: 2px;
              background-color: $tertiaryColor;
              transition: background-color ease-in-out 0.2s;
              border-radius: 2px;
            }

            &:hover {
              color: $secondaryColor;

              &:after {
                background-color: $secondaryColor;
              }
            }
          }
        }
      }

      .bottom-line {
        display: flex;
        align-items: center;
        gap: 50px;

        @media screen and (max-width: 1023px) {
          gap: 20px;
        }

        @media screen and (max-width: 767px) {
          display: none;
        }

        .nav-link-container {
          position: relative;
          display: flex;

          &.has-sub-menu {
            &.sub-menu-shown {
              &:before,
              .nav-sub-links {
                transform: translateX(-50%);
                opacity: 1;
                pointer-events: auto;
              }
            }

            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%) translateY(32px);
              width: 50px;
              height: 12px;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTEiIGhlaWdodD0iNTEiIHZpZXdCb3g9IjAgMCA5MSA1MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ1LjUgMEw5MC45NjYzIDUwLjI1SDAuMDMzNjY0N0w0NS41IDBaIiBmaWxsPSIjRURFREVEIi8+Cjwvc3ZnPgo=");
              background-repeat: no-repeat;
              background-position: center bottom;
              background-size: contain;
              opacity: 0;
              pointer-events: none;
              transition: transform ease-in-out 0.5s, opacity ease-in-out 0.5s;
            }

            .nav-sub-links {
              position: absolute;
              z-index: 10;
              top: 100%;
              left: 50%;
              transform: translateX(-50%) translateY(32px);
              opacity: 0;
              pointer-events: none;
              display: flex;
              align-items: stretch;
              background-color: $light;
              border-radius: 8px;
              box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);

              &.is-animated {
                transition: transform ease-in-out 0.5s, opacity ease-in-out 0.5s;
              }

              &.no-icons {
                flex-direction: column;

                .nav-sub-link {
                  padding: 16px 32px;

                  span {
                    font-size: 16px;
                  }
                }
              }

              .nav-sub-link {
                width: 200px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 32px 40px;
                gap: 20px;
                border-right: solid 1px rgba($lightGrey, 0.5);
                cursor: pointer;
                transition: all ease-in-out 0.2s;

                &:hover {
                  background-color: $bgLightGrey;
                }

                &:last-child {
                  border-right: none;
                }

                img {
                  height: 65px;
                  opacity: 0.7;
                }

                span {
                  @extend .lead18;
                  text-align: center;
                  color: $secondaryColor;
                }
              }
            }
          }

          &.search {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $bgBlogLightBlue;
            cursor: pointer;

            .header-blog-search {
              background: $bgBlogLightBlue;
              border: none;
              outline: none;
              position: absolute;
              right: 0;
              font-size: 0;
              border-radius: 33px;
              width: 0;
              overflow: hidden;
              pointer-events: none;
              transition: all ease-in-out 0.2s;

              &::placeholder {
                font-size: 16px;
                font-weight: 700;
              }
            }

            svg {
              position: absolute;
              right: 50%;
              top: 50%;
              transform: translate(50%, -50%);
              transition: all ease-in-out 0.2s;
            }

            &.active {
              .header-blog-search {
                padding: 12px 50px 12px 15px;
                width: 272px;
                pointer-events: all;
                transition: all ease-in-out 0.3s;
                font-size: 16px;
              }

              svg {
                right: 13px;
                transform: translate(0, -50%);
                transition: all ease-in-out 0.3s;
              }
            }
          }
        }

        &.blog .nav-link {
          padding: 10px 0;
        }

        .nav-link {
          position: relative;
          font-family: $fontMerkury;
          font-size: 21px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: $tertiaryColor;
          transition: color ease-in-out 0.2s;
          padding-bottom: 21px;
          cursor: pointer;
          margin: 0;

          @media screen and (max-width: 1023px) {
            font-size: 16px;
          }

          &:hover {
            color: $secondaryColor;
          }

          &.is-active {
            color: $secondaryColor;

            &:after {
              content: "";
              position: absolute;
              left: 50%;
              bottom: 1px;
              transform: translateX(-50%);
              width: 8px;
              height: 8px;
              background-color: $secondaryColor;
              border-radius: 8px;
            }
          }
        }
      }

      .menu-icon-container {
        display: none;

        @media screen and (max-width: 767px) {
          display: flex;
        }

        & > svg {
          cursor: pointer;
          height: 29px;

          &.close-icon {
            display: none;
          }
        }

        &.is-opened {
          .burger-icon {
            display: none;
          }

          .close-icon {
            display: flex;
          }
        }
      }
    }
  }
}

.mobile-menu {
  position: absolute;
  z-index: 10;
  bottom: 0;
  top: 62px;
  left: -100vw;
  width: 100vw;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: $light;
  transition: all ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  padding-bottom: 100px;

  &.is-active {
    left: 0;
    opacity: 1;
    pointer-events: auto;
  }

  .sub-menu {
    position: absolute;
    z-index: 12;
    bottom: 0;
    left: -100vw;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: $light;
    transition: all ease-in-out 0.2s;
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;

    &.is-active {
      left: 0;
      opacity: 1;
      pointer-events: auto;
    }
  }

  .top-section {
    @extend .container;
    padding-top: 40px;
    padding-bottom: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .nav-link {
      font-family: $fontMerkury;
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: normal;
      color: $tertiaryColor;
      padding-right: 30px;
      margin: 0;

      &.has-children {
        background-size: 24px;
        background-position: right center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48bWFzayBpZD0iYiIgc3R5bGU9Im1hc2stdHlwZTpsdW1pbmFuY2UiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjciIHk9IjMiIHdpZHRoPSIxMSIgaGVpZ2h0PSIxNyI+PHBhdGggZD0iTTcgMjBWMy41MjdoMTAuMzQ3VjIwSDdaIiBmaWxsPSIjMDA5RkRGIi8+PC9tYXNrPjxnIG1hc2s9InVybCgjYikiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTcuMzQ3IDExLjc2NGMwIC41MzctLjIxNiAxLjA1LS42IDEuNDI2bC02LjM0MyA2LjIzN2EyLjAwMiAyLjAwMiAwIDAgMS0yLjgyOS0uMDI1IDEuOTk4IDEuOTk4IDAgMCAxIC4wMjQtMi44MjhsNC44OTQtNC44MUw3LjYgNi45NTRhMS45OTggMS45OTggMCAwIDEtLjAyNC0yLjgyOGMuNzczLS43ODggMi4wNC0uOCAyLjgyOC0uMDI1bDYuMzQ1IDYuMjM3Yy4zODMuMzc2LjYuODg5LjYgMS40MjZaIiBmaWxsPSIjMDA5RkRGIi8+PC9nPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=");
      }

      &.back-link {
        font-size: 18px;
        padding-right: 0;
        padding-left: 30px;
        background-size: 18px;
        background-position: left center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48bWFzayBpZD0iYiIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB4PSIwIiB5PSIwIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBzdHlsZT0ibWFzay10eXBlOmx1bWluYW5jZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTI0IDI0SDBWMGgyNHYyNFoiLz48L21hc2s+PGcgbWFzaz0idXJsKCNiKSI+PG1hc2sgaWQ9ImMiIHdpZHRoPSIxMSIgaGVpZ2h0PSIxNyIgeD0iNiIgeT0iNCIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgc3R5bGU9Im1hc2stdHlwZTpsdW1pbmFuY2UiPjxwYXRoIGZpbGw9IiMwMDlGREYiIGQ9Ik0xNyA0djE2LjQ3M0g2LjY1M1Y0SDE3WiIvPjwvbWFzaz48ZyBtYXNrPSJ1cmwoI2MpIj48cGF0aCBmaWxsPSIjMDA5RkRGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjY1MyAxMi4yMzZjMC0uNTM3LjIxNi0xLjA1LjYtMS40MjZsNi4zNDItNi4yMzdBMi4wMDIgMi4wMDIgMCAwIDEgMTYuODQgNi43OGMtLjEwMy4yNDItLjI1Mi40NjEtLjQ0LjY0NWwtNC44OTMgNC44MSA0Ljg5MyA0LjgxYTEuOTk4IDEuOTk4IDAgMCAxIC4wMjQgMi44MjhjLS43NzQuNzg4LTIuMDQuOC0yLjgyOS4wMjVsLTYuMzQ0LTYuMjM3YTEuOTk4IDEuOTk4IDAgMCAxLS42LTEuNDI2aC4wMDJaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L2c+PC9nPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yNCAyNEgwVjBoMjR6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+");
      }

      &.search {
        background: $bgBlogLightBlue;
        margin: 24px 0 30px;
        position: relative;
        border-radius: 33px;
        overflow: hidden;

        .header-blog-search {
          background: $bgBlogLightBlue;
          border: none;
          outline: none;
          font-size: 0;
          padding: 12px 50px 12px 15px;
          width: 100%;
          font-size: 16px;

          &::placeholder {
            font-size: 16px;
            font-weight: 700;
          }
        }

        svg {
          position: absolute;
          top: 50%;
          right: 13px;
          transform: translate(0, -50%);
          transition: all ease-in-out 0.3s;
        }
      }
    }
  }

  .bottom-section {
    @extend .container;
    padding-top: 30px;
    padding-bottom: 40px;
    background-color: #f3f5f7;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    .nav-link {
      font-family: $fontMerkury;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      color: $tertiaryColor;

      &.is-button {
        @extend .button;
        @extend .small;

        &.is-secondary {
          @extend .light;
          border: solid 1px $secondaryColor;
        }
      }

      &.language-link {
        position: relative;
        color: $tertiaryColor;
        transition: color ease-in-out 0.2s;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 2px;
          width: 100%;
          height: 2px;
          background-color: $tertiaryColor;
          transition: background-color ease-in-out 0.2s;
          border-radius: 2px;
        }

        &:hover {
          color: $secondaryColor;

          &:after {
            background-color: $secondaryColor;
          }
        }
      }
    }
  }
}
