.call-to-action {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 100px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 1023px) {
    padding: 80px 0;
  }

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  .cta-container {
    position: relative;
    @extend .container;

    .cta-content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
