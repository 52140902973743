.blog-category {
  padding-top: 20px;
  margin-bottom: 64px;

  @media screen and (min-width: 768px) {
    padding-top: 16px;
    margin-bottom: 69px;
  }

  .container {
    width: 100%;
    max-width: calc(1175px + 2 * 40px);
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
      padding: 0 40px;
    }
  }
}

.category-hero {
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 55px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 40px;
    }
  }

  .point {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #71757E;
    margin: 0 4px 2px;
  }

  &__body {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 61.1%;
      flex-shrink: 0;
    }
  }

  &__img {
    width: 100%;
    padding-bottom: 57.3%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.96px;
    color: $tertiaryColor;
    margin-top: 16px;

    @media screen and (min-width: 768px) {
      font-size: 26px;
      line-height: 33px;
      margin-top: 26px;
    }
  }

  &__desc {
    font-size: 15px;
    line-height: 24px;
    color: $tertiaryColor;
    margin-top: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    font-family: 'LiberationSans', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

    @media screen and (min-width: 768px) {
      font-size: 14px;
      margin-top: 20px;
      -webkit-line-clamp: 1;
    }
  }

  &__aside {
    .blog-search {
      padding-bottom: 64px;

      @media screen and (min-width: 768px) {
        padding-bottom: 32px;
      }

      .container {
        padding: 0;
      }
    }
  }
}
