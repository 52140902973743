.aside-blog {
  &__title {
    font-weight: 700;
    font-size: 19px;
    line-height: 27px;
  }

  &__card {
    display: flex;
    gap: 21px;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover img {
      transform: scale(1.4);
    }

    &-img {
      width: 119px;
      height: 84px;
      border-radius: 4px;
      overflow: hidden;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: all ease-in-out 0.7s;
      }
    }

    &-title {
      display: flex;
      flex-direction: column;

      p {
        font-weight: 700;
        display: inline-block;
        margin-bottom: 10px;
        line-height: 113%;
        color: $tertiaryColor;
        letter-spacing: -0.96px;
        font-size: 15px;
        line-height: 17px;
      }

      span {
        font-weight: 700;
        color: $secondaryColor;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
