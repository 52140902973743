.contact-list {
  width: 100%;
  display: flex;
  justify-content: center;
  @extend .padding-top-auto;
  @extend .padding-bottom-auto;

  .content-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .contact-category {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .category-name {
        @extend .lead26;
        color: $tertiaryColor;
        margin: 0;
      }

      .contact-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 32px;

        .contact-item {
          width: calc((100% - 64px) / 3);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          background-color: $light;
          border-radius: 8px;
          padding: 16px;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

          .item-name {
            font-family: $fontMerkury;
            font-size: 21px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $dark;
            margin: 0;
            min-height: 60px;
            max-width: 260px;
          }

          .item-contacts {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .item-heading {
              width: 100%;
              font-family: $fontMerkury;
              font-size: 15px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: normal;
              color: $dark;
              margin: 0 0 6px;
            }

            .list-numbers {
              width: calc(50% - 4px);
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .phone-number, .fax-number {
                font-family: Arial;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: $secondaryColor;
                padding-left: 20px;
                background-position: left center;
                background-repeat: no-repeat;
                background-size: auto 14px;

                &:hover {
                  text-decoration: underline;
                }
              }

              .phone-number {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxkZWZzPjxwYXRoIGQ9Ik0yMC41MjMgMTYuMDg2IDE4LjEzOCAxMy43YTEuNjUgMS42NSAwIDAgMC0yLjMzMyAwbC0xLjk4IDEuOTcyYTEwLjI3NiAxMC4yNzYgMCAwIDEtNS40OTgtNS40OThsMS45NzItMS45OGExLjY1IDEuNjUgMCAwIDAgMC0yLjMzM0w3LjkxNCAzLjQ3N2ExLjY2NSAxLjY2NSAwIDAgMC0yLjMzMyAwTDQuNDUgNC42MDlBNC45ODggNC45ODggMCAwIDAgMy4xIDkuMTFhMTUuMDAyIDE1LjAwMiAwIDAgMCAxMS43OSAxMS43OTIgNC45ODggNC45ODggMCAwIDAgNC41LTEuMzVsMS4xMzMtMS4xMzNhMS42NjUgMS42NjUgMCAwIDAgMC0yLjMzM3oiIGlkPSJhIi8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIvPjx1c2UgZmlsbD0iIzAwNDdCQiIgeGxpbms6aHJlZj0iI2EiLz48L2c+PC9zdmc+");
              }

              .fax-number {
                background-size: auto 10px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMyAxNiI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLjQyNiAyKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cmVjdCBmaWxsPSIjMDA0N0JCIiB4PSIuNTc0IiB5PSI0IiB3aWR0aD0iMjEiIGhlaWdodD0iMTAiIHJ4PSIxIi8+PHBhdGggZD0iTTE1LjU3NC0xYy41NTMgMCAxLjA1My4yMjQgMS40MTUuNTg2LjM2Mi4zNjIuNTg1Ljg2Mi41ODUgMS40MTR2NmgtMTNWMWMwLS41NTIuMjI0LTEuMDUyLjU4Ni0xLjQxNEExLjk5NCAxLjk5NCAwIDAgMSA2LjU3NC0xeiIgc3Ryb2tlPSIjMDA0N0JCIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9IiNGRkYiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNNS41NzQgMTBoMTJ2MWgtMTJ6bTAgM2gxMXYxaC0xMXoiLz48L2c+PC9zdmc+");
              }
            }
          }
        }
      }
    }
  }
}
