.overflightAreas {
   width: 100%;
   display: flex;
   justify-content: center;
 
   .content-wrapper {
      @extend .container;
      @extend .padding-top-auto;
      @extend .padding-bottom-auto;
      display: flex;
      flex-direction: column;

      .content-heading {
         width: 100%;
         margin-bottom: 40px;

         @media screen and (max-width: 767px) {
            margin-bottom: 20px;
         }
      }

      .overflightAreas-container {
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         grid-template-areas: 
         'box1 box2 box3'
         'box4 box4 box5'
         'box6 box7 box8';

         @media screen and (max-width: 1023px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 
            'box1 box2'
            'box3 box4'
            'box5 box6'
            'box7 box8';
            .boxContainer {

               &.active {

                  .box-slide {
                     top: 0;
                  }
               }
            }
         }
         @media screen and (max-width: 767px) {
            grid-template-columns: 1fr;
            grid-template-areas: 
            'box1'
            'box2'
            'box3'
            'box4'
            'box5'
            'box6'
            'box7'
            'box8';

            .boxContainer {
               aspect-ratio: 2 / 1 !important;

               &.active {
                  aspect-ratio: 3 / 2 !important;

                  .svg-plus {
                     z-index: 11;
                     transform: rotate(45deg);
                     transition: all .3s ease;
                  }
               }
            }

            .boxContainerImage {
               aspect-ratio: 4 / 3 !important;
            }
         }

         .box-number-1 {
            grid-area: box1;
            background-color: $bgLighterBlue;
            aspect-ratio: 3 / 2;
         }

         .box-number-2 {
            grid-area: box2;
            background-color: #95D5F5;
            aspect-ratio: 3 / 2;
         }

         .box-number-3 {
            grid-area: box3;
            background-color: $primaryColor;
            aspect-ratio: 3 / 2;
         }

         .box-number-4 {
            grid-area: box4;
            aspect-ratio: 6 / 2;
         }

         .box-number-5 {
            grid-area: box5;
            background-color: $bgLighterBlue;
            aspect-ratio: 3 / 2;
         }

         .box-number-6 {
            grid-area: box6;
            background-color: $bgLightBlue;
            aspect-ratio: 3 / 2;
         }

         .box-number-7 {
            grid-area: box7;
            background-color: #95D5F5;
            aspect-ratio: 3 / 2;
         }

         .box-number-8 {
            grid-area: box8;
            aspect-ratio: 3 / 2;
         }

         .boxContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            overflow: hidden;
            padding: 30px 25px;

            p {
               margin: 0;
               line-height: 1.29;
            }

            .svg-plus {
               position: absolute;
               z-index: 5;
               top: 20px;
               right: 20px;
            }

            .box-slide {
               position: absolute;
               display: flex;
               flex-direction: column;
               inset: 0;
               height: 100%;
               width: 100%;
               top: 100%;
               padding: 30px;
               z-index: 10;
               transition: all .3s ease;
               color: $tertiaryColor;
               background-color: #EFF6F8;
            }

            &:hover {
               .box-slide {
                  top: 0;
               }
            }
         }

         .boxContainerImage {
            height: 100%;
            width: 100%;
            overflow: hidden;
            
            img {
               height: 100%;
               width: 100%;
               object-fit: cover;
            }
         }
      }
   }
} 