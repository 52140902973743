.image-galery {
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }
  .content-galery {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content-nav-bar {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      list-style-type: none;
      padding: 0;
      margin: 0;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      .nav-link {
        display: flex;
        height: 80px;
        border: 1px solid $lightGrey;
        border-bottom: 4px solid $secondaryColor;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        text-align: center;
        margin-right: -1px;
        padding: 0 15px;
        font-size: 18px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          border-bottom: none;
          justify-content: flex-start;
          height: 60px;
          &:last-child {
            border-bottom: 1px solid $lightGrey;
          }
        }

        &.is-active {
          background-color: $secondaryColor;
          color: white;
          border: 1px solid $secondaryColor;
        }
      }
    }

    .all-content-container {
      .content-container {
        width: 100%;
        margin: 30px 0;

        &.content-images-container {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .image-filters {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin: 0 0 15px;
            padding: 13px 8px;
            background-color: $bgLightBlue;
            list-style-type: none;
            cursor: pointer;

            @media screen and (max-width: 767px) {
              flex-direction: column;
              align-items: flex-start;
            }

            .image-filter {
              padding: 5px 10px;
              font-weight: 500;

              @media screen and (max-width: 767px) {
                padding: 3px 10px;
              }

              &:hover {
                color: $primaryColor;
              }

              &.is-active {
                font-weight: 700;
                color: $primaryColor;
              }
            }
          }

          .all-images-containers {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;

            .image-content {
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: calc(25% - 15px);

              @media screen and (max-width: 1023px) {
                width: calc(33% - 13px);
              }

              @media screen and (max-width: 767px) {
                width: calc(50% - 10px);
              }

              .image-container {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 56%;
                overflow: hidden;
                background-color: #f7f8fa;
                border: 1px solid $lightGrey;

                img {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  object-fit: contain;
                }
              }

              .image-title {
                margin: 0;
              }

              .download-link {
                color: $primaryColor;

                &:hover {
                  color: $tertiaryColor;
                }
              }
            }
          }
        }

        &.content-videos-container {
          display: none;
          flex-direction: column;

          .all-videos-container {
            display: flex;
            gap: 20px;
            margin: 15px 0;
            flex-wrap: wrap;

            .video-content {
              display: flex;
              flex-direction: column;
              gap: 10px;
              width: calc(33% - 15px);

              @media screen and (max-width: 1023px) {
                width: calc(50% - 10px);
              }

              @media screen and (max-width: 767px) {
                width: calc(50% - 10px);
              }

              a {
                position: relative;
                display: block;
                aspect-ratio: 16 / 9;
                overflow-y: hidden;

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  object-position: center;
                }
                &::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  height: 100px;
                  width: 140px;
                  z-index: 2;
                  transform: translate(-50%, -50%);
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='%23fff' height='40px' width='40px' version='1.1' id='Layer_1' viewBox='0 0 512.055 512.055' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M500.235,236.946L30.901,2.28C16.717-4.813,0.028,5.502,0.028,21.361v469.333c0,15.859,16.689,26.173,30.874,19.081 l469.333-234.667C515.958,267.247,515.958,244.808,500.235,236.946z M42.694,456.176V55.879l400.297,200.149L42.694,456.176z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                  background-repeat: no-repeat;
                  background-position: center;

                  @media screen and (max-width: 767px) {
                    width: 70px;
                    height: 50px;
                    background-size: 25px;
                  }
                }
              }
            }
          }
        }

        &.content-graphicsStandard-container {
          display: none;
          flex-direction: column;

          .graphics-standard-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            h2,
            h3,
            h4 {
              margin: 0;
            }

            .button {
              padding: 8px 15px;
              max-width: 400px;
            }
          }
        }

        &.content-logos-container {
          display: none;
          flex-direction: column;

          .all-logos-container {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex: 1 1 20%;
            margin-top: 30px;

            &::before {
              content: "";
              position: absolute;
              height: 1px;
              width: 100%;
              background-color: $lightGrey;
              left: 0;
              top: -30px;
            }

            .logo-container {
              display: flex;
              flex-direction: column;
              width: calc(25% - 20px);
              gap: 10px;

              @media screen and (max-width: 1023px) {
                width: calc(50% - 10px);
              }

              @media screen and (max-width: 767px) {
                width: calc(50% - 10px);
              }

              .image-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                aspect-ratio: 16 / 9;
                background-color: #f7f8fa;
                border: 1px solid $lightGrey;
              }

              h3 {
                margin: 0;
                font-size: 18px;
              }

              .all-type-container {
                display: flex;
                flex-direction: column;
                gap: 3px;

                p {
                  margin: 0;
                }
              }
            }
          }
        }

        .legal {
          text-align: center;
        }
      }
    }
  }
}
