.conversion-factors {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @extend .padding-top-auto;
  @extend .padding-bottom-auto;

  .content-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;
  }

  .conversion-outer-wrapper {
    padding: 42px 0;
    background-color: $bgGrey;
  }

  .conversion-wrapper {
    @extend .container;
    display: flex;

    @media(max-width: 1024px) {
      flex-direction: column;
    }
  }

  .conversion-calculator-wrapper {
    width: 60%;

    @media(max-width: 1024px) {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .conversion-calculator {
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(48, 49, 51, 0.13);
    background: $light;

    .conversion-fields {
      padding: 39px 99px 39px 39px;

      @media (max-width: 768px) {
        padding: 30px 20px;
      }
    }

    .unit-input {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      background: $light;
      padding: 12px 9px 10px;
      border-radius: 4px;
      border: 1px solid $lightGrey;
      cursor: pointer;

      p
      {
        margin: 0;
        font-family: $fontArial;
        font-size: 13px;
        line-height: 1.54;

        &.is-placeholder {
          color: $grey;
        }
      }

      .dropdown-arrow {
        margin-left: auto;
        transition: transform .2s ease-in-out;

        &.is-active {
          transform: rotate(-180deg);
        }
      }

      .unit-dropdown {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: 50px;
        z-index: 10;
        background: $light;
        border-radius: 4px;
        border: 1px solid $lightGrey;
        max-height: 175px;
        overflow: scroll;

        &.is-active {
          display: block;
        }

        p {
          padding: 10px;
          font-family: $fontArial;
          font-size: 13px;
          line-height: 1.54;

          &:not(:last-child) {
            border-bottom: 1px solid $lightGrey;
          }

          &:hover {
            background: $bgLightGrey;
          }

          &.is-disabled {
            display: none;
          }
        }
      }
    }

    p.unit-label {
      position: absolute;
      top: 0;
      font-family: $fontArial;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: #0047bb;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      transition: opacity .1s ease-in-out;

      &.is-active {
        opacity: 1;
      }
    }

    input.unit-input
    {
      margin: 0;
      font-family: $fontArial;
      font-size: 13px;
      line-height: 1.54;
      max-width: 240px;
      outline: none;
      cursor: text;

      &::placeholder,
      &::-webkit-input-placeholder,
      &::-moz-placeholder{
        color: $grey;
      }
    }

    .units-selector-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .calculator-inputs {
        display: flex;
        width: 100%;
      }

      .arrow-separator {
        margin: 0 30px;
        flex: none;

        @media (max-width: 768px) {
          margin: 10px 0 10px 20px;
          transform: rotate(90deg);
        }
      }
    }

    .calculator-title {
      margin: 39px 0 8px;
      font-family: $fontMerkury;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: $tertiaryColor;
    }

    .calculator-error {
      display: none;

      &.is-visible {
        display: inline-block;
        color: $colorError;
      }
    }

    .unit-wrapper {
      position: relative;

      .unit-label {
        left: 9px;
      }
    }

    .calculator-button {
      border: none;
      margin-top: 24px;
      padding: 11px 60px 10px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .result-wrapper {
      opacity: 0;
      background: rgba($bgLighterBlue, .5);
      padding: 32px 40px 27px;
      border-radius: 0 0 10px 10px;
      z-index: -1;
      pointer-events: none;

      @media (max-width: 768px) {
        display: none;
      }

      &.is-active {
        opacity: 1;
        transition: all .1s ease-in;
        z-index: 1;
        pointer-events: auto;

        @media (max-width: 768px) {
          display: block;
        }
      }

      p {
        margin: 0;
        font-family: $fontMerkury;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: normal;
        color: $tertiaryColor;
      }
    }
  }

  .conversion-info-wrapper {
    margin-left: 52px;
    flex: auto;

    @media (max-width: 1024px) {
      margin-left: 0;
    }

    .info-wrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1024px) {
        max-width: 345px;
      }

      p {
        margin: 5px 0 0;
        font-family: $fontMerkury;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.77;
        letter-spacing: normal;
        color: $dark;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .conversion-info-title {
      margin-top: 25px;
      margin-bottom: 5px;
      font-family: $fontMerkury;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: $tertiaryColor;
    }
  }
}
