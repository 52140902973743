.content-with-slider {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  &.bg-color-dark {
    .flickity-page-dots {
      bottom: -40px;

      .dot {
        background-color: $light !important;
        transition: all 0.3s ease-in-out;

        &.is-selected {
          background-color: $light !important;
        }
      }
    }
  }

  .content-wrapper {
    @extend .container;
    @extend .padding-top-auto;
    @extend .padding-bottom-auto;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .content-columns {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        gap: 20px;
      }

      .column-side {
        width: calc(50% - 20px);
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1023px) {
          width: calc(50% - 10px);
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .flickity-page-dots {
          bottom: -40px;

          @media screen and (max-width: 767px) {
            bottom: -20px;
          }

          .dot {
            background-color: $lightText;
            transition: all 0.3s ease-in-out;

            &.is-selected {
              background-color: $secondaryColor;
            }
          }
        }

        .slider-item {
          width: 100%;
        }

        .image-element {
          width: 100%;
        }

        .image-content {
          padding: 20px;
          width: 100%;
          background-color: $primaryColor;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3px;

          & > * {
            margin: 0;
            color: $light;
          }
        }
      }
    }
  }
}
