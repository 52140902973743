// --------------------------------------------------
// Variables
// --------------------------------------------------

// Import Web Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

// Fonts
$fontRoboto: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontMerkury: 'Merkury', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontArial: 'ArialMT', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Accents Colors
$primaryColor: #009fdf;
$secondaryColor: #0047bb;
$tertiaryColor: #002855;
$bgLightBlue: #ecf6f9;
$bgLighterBlue: #daeef4;
$bgLightGrey: #f8f9fa;
$bgGrey: #f2f4f6;
$bgBlogLightBlue: #E3EAF2;
$blogGrey: #93989E;

$lightText: #73879A;

// Texts
$dark: #303133;
$grey: #8d8d8f;
$inactiveGrey: #aeafb1;
$lighterInactiveGrey: #c5c5c5;
$lightGrey: #dadada;
$light: #FFFFFF;

$colorSuccess: #00a376;
$warning: #ffcf63;
$colorError: #eb5b50;
