.image-and-video {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  .content-wrapper {
    @extend .container;
    @extend .padding-top-auto;
    @extend .padding-bottom-auto;
    display: flex;
    flex-direction: column;

    &.fullscreen {
      padding: 0;
      max-width: none;
    }

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .assets-container {
      width: 100%;

      .video-container {
        position: relative;
        width: 100%;
        padding-top: 56.25%;

        &.inline {
          padding-top: 0;
        }

        video {
          width: 100%;
        }

        iframe {
          position: absolute;
          inset: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }

      .image-container {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
