.content-double-advanced {
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .content-columns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 60px;

      @media screen and (max-width: 1023px) {
        flex-direction: column;
      }

      @media screen and (max-width: 767px) {
        gap: 20px;
      }

      .content-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        width: 50%;

        @media screen and (max-width: 1023px) {
          width: 100%;
        }

        .content-row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 40px;
          width: 100%;
          @media screen and (max-width: 600px) {
            flex-direction: column;
          }
          &-image-container {
            min-width: 180px;
            width: 30%;
            border-radius: 4px;
            overflow: hidden;
            @media screen and (max-width: 1023px) {
              min-width: 50%;
              width: 50%;
            }
            @media screen and (max-width: 600px) {
              width: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          &-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }
}
