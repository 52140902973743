.partners-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  @media screen and (max-width: 1023px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 40px;
  }

  .heading-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }

  .main-section {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f2f4f6;

    .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      .form-section-full {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 30px 0;
        border-bottom: solid 1px #d0d3d4;

        .form-section-wrapper {
          @extend .container;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 20px;

          @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: flex-start;
          }

          @media screen and (max-width: 767px) {
            align-items: center;
          }

          .form-label {
            width: 100%;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.39;
            letter-spacing: normal;
            color: $tertiaryColor;
            margin-bottom: -12px;

            @media screen and (max-width: 767px) {
              text-align: center;
            }
          }

          .input-text, .input-select {
            padding: 10px 20px;
            border-radius: 4px;
            border: solid 1px $lightGrey;
            outline: none;
            appearance: none;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: normal;
            color: $dark;
            min-width: 300px;

            @media screen and (max-width: 767px) {
              width: 100%;
            }

            &:placeholder {
              color: #8d8d8f;
            }
          }

          .input-select {
            max-width: 350px;
            padding-right: 54px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNSAxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iMHhvY3l1eHR4YSIgZD0iTTAgMGgxMy43Mjd2OC42MjJIMHoiLz4KICAgIDwvZGVmcz4KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC4zMzMgLjgzMykiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPG1hc2sgaWQ9ImloNHE3NXdndGIiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiMweG9jeXV4dHhhIi8+CiAgICAgICAgPC9tYXNrPgogICAgICAgIDxwYXRoIGQ9Ik02Ljg2NCA4LjYyMmMtLjQ0OCAwLS44NzUtLjE4LTEuMTg5LS40OTlMLjQ3OCAyLjgzNkExLjY2NiAxLjY2NiAwIDAgMSAyLjg1NS40OThsNC4wMDggNC4wNzhMMTAuODczLjVhMS42NjUgMS42NjUgMCAwIDEgMi4zNTctLjAyYy42NTYuNjQ0LjY2NiAxLjcuMDIgMi4zNTdMOC4wNTIgOC4xMjNjLS4zMTMuMzItLjc0MS41LTEuMTg4LjUiIGZpbGw9IiMwMDlGREYiIG1hc2s9InVybCgjaWg0cTc1d2d0YikiLz4KICAgIDwvZz4KPC9zdmc+Cg==");
            background-position: right 20px center;
            background-size: 14px;
            background-repeat: no-repeat;
          }

          .input-button {
            @extend .button;
            border: none;
            outline: none;
            appearance: none;

            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }

      .results-section {
        @extend .container;
        padding-top: 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 30px;
        padding-bottom: 100px;

        @media screen and (max-width: 1023px) {
          padding-bottom: 80px;
        }

        @media screen and (max-width: 767px) {
          padding-bottom: 40px;
        }

        &.no-results-yet {
          .result-card, .no-results { display: none }
        }

        &.no-results {
          .result-card, .no-results-yet { display: none }
        }

        &.has-results {
          .no-results-yet, .no-results { display: none }
        }

        .result-card {
          width: calc((100% - 60px) / 3);
          padding: 16px;
          border-radius: 10px;
          background-color: $light;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

          @media screen and (max-width: 1023px) {
            width: calc((100% - 30px) / 2);
          }

          @media screen and (max-width: 767px) {
            width: 100%;
          }

          .card-heading {
            position: relative;
            font-size: 21px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $dark;
            min-height: calc(21px * 1.33 * 3);

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 32px;
              height: 1px;
              background-color: #d0d3d4;
            }
          }

          .card-subheading {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: $dark;
            margin-bottom: 10px;
          }

          .card-address {
            font-family: $fontArial;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: $dark;
            margin-bottom: 16px;
          }

          .contact-info {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 30px;

            .phone-link {
              font-family: $fontArial;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              color: $secondaryColor;
            }

            .website-link {
              display: flex;
              align-items: center;
              font-family: $fontMerkury;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.29;
              letter-spacing: normal;
              color: $secondaryColor;

              &:after {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-left: 8px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBkPSJNMTAuMzMzIDN2My42aC01djEwLjhoMTMuMzM0di0zLjhIMjJ2My44YzAgMS45ODgtMS40OTIgMy42LTMuMzMzIDMuNkg1LjMzM0MzLjQ5MyAyMSAyIDE5LjM4OCAyIDE3LjRWNi42QzIgNC42MTIgMy40OTIgMyA1LjMzMyAzaDV6bTExLjY3Ni0uOTI1LS4xNTIgNy40MzdhMS4yNzcgMS4yNzcgMCAwIDEtLjM3Ni44NzJjLS40ODguNDg5LTEuMjYzLjUwNi0xLjczMi4wMzdMMTcuODM3IDguNTEgMTQuMyAxMi4wNDVjLS42NS42NS0xLjY4NS42NzItMi4zMS4wNDdzLS42MDMtMS42NTkuMDQ3LTIuMzFsMy41MzYtMy41MzUtMS45MTItMS45MTJhMS4xNzYgMS4xNzYgMCAwIDEtLjM0LS44NTggMS4yODcgMS4yODcgMCAwIDEgMS4yNDktMS4yNWw3LjQzOC0uMTUyeiIgaWQ9ImtnZXc3eXBjMGEiLz4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIvPgogICAgICAgIDx1c2UgZmlsbD0iIzAwNDdCQiIgeGxpbms6aHJlZj0iI2tnZXc3eXBjMGEiLz4KICAgIDwvZz4KPC9zdmc+Cg==");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
          }
        }

        .show-more-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;

          .show-more-button {
            @extend .button;
            @extend .outlined;
          }
        }
      }
    }
  }
}
