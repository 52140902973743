.image-quote {
   width: 100%;
   display: flex;
   justify-content: center;
   
   .content-wrapper {
     @extend .container;
     display: flex;
     flex-direction: column;

      .content-heading {
         width: 100%;
         margin-bottom: 40px;
         @media screen and (max-width: 767px) {
         margin-bottom: 20px;
         }
      }

      .content-columns {
         display: flex;
         flex-direction: row;
         gap: 40px;
         align-items: center;
         @media screen and (max-width: 767px) {
            flex-direction: column-reverse;
            gap: 20px;
            align-items: center;
         }

         .container-image {
            display: flex;
            justify-content: flex-end;
            width: calc(40% - 20px);

            div {
               border-radius: 50%;
               overflow: hidden;
               width: 100%;
               max-width: 260px;
               max-height: 260px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }
         }

         .container-quote {
            max-width: calc(60% - 20px);
            width: 100%;
            @media screen and (max-width: 767px) {
               width: 100%;
               max-width: none;
            }

            svg {
               margin-bottom: 20px;
            }

            .quote-text {
               margin-bottom: 20px;
            }

            .quote-author {
               margin: 0;
               font-weight: normal;
            }

            .quote-author-job {
               margin: 0;
               font-weight: lighter;
            }
         }

         &.alignement-right {
            flex-direction: row-reverse;
            @media screen and (max-width: 767px) {
               flex-direction: column-reverse;
            }
         }
      }
   }
}