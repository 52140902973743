.membersList {
  width: 100%;
  display: flex;
  justify-content: center;

  &.bg-color-dark .member-name {
    color: $light !important;
  }

  .content-wrapper {
    @extend .container;
    @extend .padding-top-auto;
    @extend .padding-bottom-auto;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
    }

    .members-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .member-item {
        width: calc((100% - 120px) / 4);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 40px;
        margin-right: 40px;

        &:nth-child(4n + 4) {
          margin-right: 0;
        }

        @media screen and (max-width: 1023px) {
          width: calc((100% - 80px) / 3);

          &:nth-child(4n + 4) {
            margin-right: 40px;
          }

          &:nth-child(3n + 3) {
            margin-right: 0;
          }
        }
        @media screen and (max-width: 767px) {
          margin-top: 20px;
          margin-right: 20px;
          width: calc((100% - 20px) / 2);

          &:nth-child(3n + 3) {
            margin-right: 20px;
          }

          &:nth-child(2n + 2) {
            margin-right: 0;
          }
        }

        .member-photo {
          width: 100%;
        }

        .member-name {
          margin: 18px 0 0;
          @extend .lead18;
          font-weight: 700;
          color: $tertiaryColor;
        }

        .member-job {
          margin: 8px 0 0;
          font-size: 16px;
          font-weight: 400;
          color: $lightText;
        }
      }
    }
  }
}
