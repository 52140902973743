.linksList {
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    @extend .container;
    @extend .padding-top-auto;
    @extend .padding-bottom-auto;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .links-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #d0d3d4;

      .link-item {
        position: relative;
        width: 100%;
        padding: 20px 60px 20px 0;
        border-bottom: 1px solid #d0d3d4;
        @extend .lead26;
        line-height: 1.35;
        letter-spacing: normal;

        @media screen and (max-width: 767px) {
          padding: 10px 40px 10px 0;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%) rotate(-90deg);
          width: 28px;
          height: 28px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMjggMjgiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMCAwaDE5LjIxOHYxMi4wNzFIMHoiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMjggMHYyOEgwVjB6Ii8+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC42NjcgOC4xNjcpIj48bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48cGF0aCBkPSJNOS42MDkgMTIuMDcxYTIuMzMgMi4zMyAwIDAgMS0xLjY2NC0uNjk5TC42NyAzLjk3QTIuMzMzIDIuMzMzIDAgMCAxIDMuOTk3LjY5OGw1LjYxMiA1LjcxTDE1LjIyLjY5OGEyLjMzIDIuMzMgMCAwIDEgMy4zLS4wMjhjLjkyLjkwMi45MzMgMi4zOC4wMyAzLjNsLTcuMjc3IDcuNDAyYTIuMzMgMi4zMyAwIDAgMS0xLjY2NC42OTkiIGZpbGw9IiMwMDQ3QkIiIG1hc2s9InVybCgjYikiLz48L2c+PC9nPjwvc3ZnPg==");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;

          @media screen and (max-width: 767px) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
