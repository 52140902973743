.blog-search {
  padding-bottom: 52px;

  @media screen and (min-width: 768px) {
    padding-bottom: 38px;
  }

  .container {
    width: 100%;
    max-width: calc(1175px + 2 * 40px);
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
      padding: 0 40px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: $tertiaryColor;
    margin: 0;
  }

  &__input {
    margin-top: 18px;
    border: 1px solid $secondaryColor;
    border-radius: 33px;
    outline: none;
    padding: 15px;
    line-height: 1;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 354px;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 1;
      font-weight: 700;
    }
  }
}
