.tabs-content {
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    @extend .container;
  }

  .desktop-tabs {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobile-tabs {
    width: 100%;

    @media (min-width: 769px) {
      display: none;
    }

    .tab-content-container {
      display: none;
      padding-bottom: 0;

      &.is-active {
        display: flex;
        padding-bottom: 40px;
      }
    }
  }

  &.tabs-type-number {
    div.tab-header {
      padding: 15px 20px;

      &.is-active,
      &:hover {
        .tab-title {
          color: $tertiaryColor;
        }

        .tabs-number {
          color: $secondaryColor;
        }
      }

      .tab-title {
        font-size: 15px;
        color: $inactiveGrey;
      }

      .tabs-number {
        margin: 0 20px 0 0;
        font-family: $fontMerkury;
        font-size: 51px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $lighterInactiveGrey;
        line-height: 51px;
      }
    }
  }

  &.tabs-type-icon {
    div.tab-header {
      .tab-title {
        font-size: 14px;
        color: $tertiaryColor;
      }
    }
  }

  .tabs-wrapper {
    display: flex;
    align-items: stretch;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .tab-header {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      background-color: $bgGrey;
      cursor: pointer;
      padding: 25px;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      &:not(:last-child):after {
        content: "";
        position: absolute;
        height: 50px;
        width: 3px;
        background: url("../img/site/border-stroke-round.svg");
      }

      &:not(:last-child):after {
        right: 0;
      }

      &.is-active,
      &:hover {
        border-radius: 0;
        background-color: $light;
        border-bottom: 2px solid $secondaryColor;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.11);
      }

      &.is-hidden:after,
      &.is-active:after {
        display: none;
      }

      .tab-title {
        margin: 0;
        font-family: $fontMerkury;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
      }
    }
  }

  .tabs-content-wrapper {
    .tab-content-container {
      @media (min-width: 769px) {
        display: none;
      }

      &.is-active {
        display: flex;
      }

      .tab-content {
        width: 100%;
      }
    }
  }
}
