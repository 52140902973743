.blog-new-cards {
  padding-bottom: 105px;

  @media screen and (min-width: 768px) {
    padding-bottom: 115px;
  }

  .container {
    width: 100%;
    max-width: calc(1175px + 2 * 40px);
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
      padding: 0 40px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: -0.9275px;
    color: $secondaryColor;
    margin-bottom: 25px;

    @media screen and (min-width: 768px) {
      font-size: 32px;
    }
  }

  &__body {
    display: grid;
    gap: 74px;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 30px;
    }
  }
  
  &__empty {
    font-size: 16px;
    line-height: 18px;
    color: $tertiaryColor;
    text-align: center;
    margin-top: 40px;
  }

  .pagination-blog {
    max-width: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
      margin-top: 115px;
    }

    &__btn {
      cursor: pointer;
      flex-shrink: 0;
    }

    &__body {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 30px;
    }

    &__item {
      font-size: 16px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $tertiaryColor;
      padding: 4px 8px;
      margin: 0 6px;
      cursor: pointer;

      &.active {
        background: $tertiaryColor;
        border-radius: 100%;
        color: #fff;
      }

      @media screen and (max-width: 767px) {
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: none;
        }
      }
    }
  }
}