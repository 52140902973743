.hero-banner {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;

  .flickity-viewport {
    width: 100%;
  }

  .flickity-page-dots {
    bottom: 20px;

    .dot {
      background-color: $secondaryColor;
    }
  }

  .hero-slide {
    position: relative;
    width: 100%;
    display: flex;
    padding: 70px 0 25px;
    min-height: 540px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      min-height: auto;
    }

    &.light {
      .slider-container .slide-content {
        color: $light;

        a:not(.button):not(.link-no-effect) {
          color: $light;
        }

        @media screen and (max-width: 767px) {
          color: $tertiaryColor;

          a:not(.button):not(.link-no-effect) {
            color: $tertiaryColor;
          }
        }
      }
    }

    .slide-bg {
      position: absolute;
      inset: 0;

      @media screen and (max-width: 767px) {
        position: relative;
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        &.mobile-bg {
          display: none;

          @media screen and (max-width: 767px) {
            display: block;
          }
        }

        &.desktop-bg {
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    .slider-container {
      position: relative;
      @extend .container;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .slide-content {
        width: 100%;
        max-width: 480px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a:not(.button):not(.link-no-effect) {
          color: $tertiaryColor;
        }

        @media screen and (max-width: 767px) {
          padding: 32px 0;
        }
      }

      &.direction-reverse {
        justify-content: flex-end;

        .slide-content {
          align-items: flex-end;
          text-align: right;
        }
      }
    }
  }
}
