.footer {
  width: 100%;
  padding-bottom: 35px;
  background-color: #f3f5f7;

  .content-wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;

    .footer-nav {
      display: grid;
      grid-template-columns: 210px repeat(2, 1fr) 325px;
      grid-template-areas:
        "col1 col2 col3 contact-us"
        "col1 col4 col4 contact-us";
      gap: 40px;

      @media screen and (max-width: 1100px) {
        grid-template-columns: 210px repeat(1, 1fr) 275px;
        grid-template-areas:
          "col1 col2 contact-us"
          "col1 col4 col3";
        gap: 30px;
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "col1"
          "col2"
          "col3"
          "col4"
          "contact-us";
        gap: 20px;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 5px;
        list-style-type: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 767px) {
          display: none;
        }

        li a {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 5px 0;
        }
      }

      .footer-content-left {
        grid-area: col1;
        padding-top: 50px;

        @media screen and (max-width: 767px) {
          padding-top: 30px;
        }

        p {
          position: relative;

          svg {
            display: none;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-25%);

            @media screen and (max-width: 600px) {
              display: block;
            }
          }
        }
      }
      .footer-content-center-left {
        grid-area: col2;
        padding-top: 50px;

        @media screen and (max-width: 767px) {
          padding-top: 0;
        }

        p {
          position: relative;

          svg {
            display: none;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-25%);

            @media screen and (max-width: 767px) {
              display: block;
            }
          }
        }
      }
      .footer-content-center-right {
        grid-area: col3;
        padding-top: 50px;

        @media screen and (max-width: 1100px) {
          padding-top: 0;
        }

        p {
          position: relative;

          svg {
            display: none;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-25%);

            @media screen and (max-width: 767px) {
              display: block;
            }
          }
        }
      }

      .footer-content-center-bottom {
        grid-area: col4;
        width: 100%;

        p {
          position: relative;

          svg {
            display: none;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-25%);

            @media screen and (max-width: 767px) {
              display: block;
            }
          }
        }

        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          color: $dark;
          gap: 40px;
          row-gap: 14px;

          @media screen and (max-width: 1100px) {
            flex-direction: column;
          }

          @media screen and (max-width: 767px) {
            display: none;
          }

          li {
            display: flex;
            align-items: center;
            width: calc(50% - 20px);

            @media screen and (max-width: 1100px) {
              width: 100%;
            }

            svg {
              margin-right: 10px;
            }
          }
        }
      }

      .footer-contact-us {
        grid-area: contact-us;

        .contact-us-container {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          background-color: #e1eaf3;
          padding: 50px 30px 25px;

          @media screen and (max-width: 767px) {
            transform: translateX(-20px);
            max-width: 288px;
          }

          .button.light {
            border: 1px solid $secondaryColor;
            background-color: #e1eaf3;
          }

          .social-media {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
          }

          .lead26 {
            margin-bottom: 10px;
          }

          .sub-title {
            margin: 25px 0 15px;
          }
        }
      }

      @media screen and (max-width: 767px) {
        .is-opened {
          p > svg {
            transition: all 0.5s ease;
            transform: rotate(180deg);
          }

          ul {
            margin-top: 20px;
            display: block;
          }
        }
      }
    }

    .sub-footer {
      display: grid;
      grid-template-columns: 210px repeat(2, 1fr) 325px;
      grid-template-rows: 50px;
      gap: 40px;
      grid-template-areas: "logo nav nav copyright";
      margin-top: 35px;

      @media screen and (max-width: 1100px) {
        grid-template-columns: 210px 1fr;
        grid-template-areas:
          "nav nav"
          "logo copyright";
        gap: 20px;
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "nav"
          "logo"
          "copyright";
        gap: 20px;
        grid-template-rows: none;
      }

      .logo-container {
        display: flex;
        justify-content: center;
        grid-area: logo;
        width: 100%;
        height: 100%;
        max-width: 175px;

        @media screen and (max-width: 600px) {
          max-width: 125px;
        }

        .home-link {
          display: flex;
          align-items: center;
        }

        .home-link-logo {
          max-height: 50px;

          @media screen and (max-width: 1023px) {
            max-height: 40px;
          }

          @media screen and (max-width: 767px) {
            max-height: 30px;
          }

          &.gazmetro {
            max-height: 90px;

            @media screen and (max-width: 1023px) {
              max-height: 80px;
            }

            @media screen and (max-width: 767px) {
              max-height: 70px;
            }
          }
        }
      }

      .sub-menu-navigation {
        display: flex;
        flex-wrap: wrap;
        grid-area: nav;
        align-items: center;

        @media screen and (max-width: 1100px) {
          justify-content: center;
        }

        @media screen and (max-width: 600px) {
          justify-content: flex-start;
          flex-wrap: wrap;
          transform: translateX(-10px);
          max-width: 300px;
        }

        #ot-sdk-btn.ot-sdk-show-settings,
        a {
          white-space: nowrap;
          word-wrap: normal;
          padding: 5px 10px;
          color: $dark;
          font-size: 14px;
          line-height: normal;
          border: none;
          background-color: transparent;
        }

        #ot-sdk-btn.ot-sdk-show-settings:hover {
          background-color: transparent;
        }

        .sub-menu-lang {
          @media screen and (max-width: 600px) {
            display: none;
          }
        }

        .sub-menu-lang-mobile {
          display: none;
          position: relative;
          width: 100%;
          margin-top: 15px;
          text-decoration: underline;
          text-decoration-thickness: 2px;
          color: $tertiaryColor;
          font-size: 16px;

          @media screen and (max-width: 600px) {
            display: block;
          }
        }
      }

      .copyright {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        grid-area: copyright;

        @media screen and (max-width: 1100px) {
          justify-content: flex-end;
        }

        @media screen and (max-width: 600px) {
          justify-content: flex-start;
        }

        &.push-end {
          align-items: flex-end;

          @media screen and (max-width: 1100px) {
            align-items: center;
          }
  
          @media screen and (max-width: 600px) {
            align-items: center;
          }

          p {
            text-align: right;
          }
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          border: 1px solid $secondaryColor;
          background-color: transparent;
        }

        p {
          margin: 0;
          color: $dark;
        }
      }
    }
  }
}
