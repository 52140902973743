.button {
  width: auto;
  position: relative;
  overflow: hidden;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  min-width: 92px;
  min-height: 34px;
  object-fit: contain;
  font-family: $fontMerkury;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $light;
  padding: 10px 15px;
  border-radius: 26px;
  background-color: $secondaryColor;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  text-decoration: none;

  svg {
    fill: currentColor;

    path {
      fill: currentColor;
    }
  }

  &:hover {
    background-color: $tertiaryColor !important;
    text-decoration: underline !important;
  }

  &:focus {
    outline: 3px solid $primaryColor !important;
  }

  &.is-clicked {
    background-color: $tertiaryColor !important;
    text-decoration: underline !important;
    border: 2px solid $secondaryColor !important;
  }

  &.secondary {
    background-color: $tertiaryColor !important;

    &:hover {
      background-color: $secondaryColor !important;
    }
  }

  &.outlined {
    background-color: transparent !important;
    border: solid 1px $secondaryColor !important;
    color: $secondaryColor !important;

    &:hover {
      background-color: $primaryColor !important;
      border-color: $primaryColor !important;
      color: $light !important;
      text-decoration: underline !important;
    }

    &:focus {
      outline: 3px solid $primaryColor !important;
    }

    &:disabled,
    &.disabled {
      background-color: #D0D3D4 !important;
      color: #5B6770 !important;
      border-color: #5B6770 !important;
    }

    &.is-clicked {
      background-color: $primaryColor !important;
      border-color: $primaryColor !important;
      color: $light !important;
      text-decoration: underline !important;
      border: 2px solid $secondaryColor !important;
    }
  }

  &:disabled,
  &.disabled {
    background-color: #D0D3D4;
    color: #5B6770;
    pointer-events: none;
  }

  // &.light {
  //   background-color: $light;
  //   color: $secondaryColor;

  //   &:hover {
  //     background-color: $light;
  //     color: $secondaryColor;
  //     opacity: 0.65;
  //   }

  //   &.outlined {
  //     border: solid 1px $light;
  //     background-color: transparent;
  //     color: $light;

  //     &:hover {
  //       border-color: $light;
  //       background-color: transparent;
  //       color: $light;
  //     }
  //   }
  // }

  &.small {
    min-width: 80px;
    min-height: 28px;
    font-size: 13px;
    line-height: 28px;
    padding: 0 10px;
  }

  &.large {
    min-width: 110px;
    min-height: 46px;
    font-size: 16px;
    line-height: 46px;
    padding: 8px 20px;
    border-radius: 32px;
  }

  &.with-icon {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      margin-right: 10px;
    }
  }

  &.blog {
    width: fit-content;
    padding: 12px 30px;
    border: none;
    background: $secondaryColor;
    font-size: 16px;
    line-height: 119%;

    &:hover {
      opacity: 0.9;
    }
  }

  &.primary-light {
    color: $secondaryColor !important;
    background-color: $light !important;

    &:hover {
      background-color: $tertiaryColor !important;
      color: $light !important;
      text-decoration: underline !important;
    }

    &:focus {
      outline: 3px solid #95D5F5 !important;
    }

    &.is-clicked {
      background-color: $tertiaryColor !important;
      color: $light !important;
      text-decoration: underline !important;
      border: 2px solid $light !important;
    }
  }

  &.primary-light-2 {
    color: $tertiaryColor !important;
    background-color: $light !important;

    &:hover {
      background-color: $secondaryColor !important;
      color: $light !important;
      text-decoration: underline !important;
    }

    &:focus {
      color: $secondaryColor !important;
      outline: 3px solid #95D5F5 !important;
    }

    &.is-clicked {
      border: 2px solid $light !important;
      background-color: $secondaryColor !important;
      color: $light !important;
      text-decoration: underline !important;
    }
  }

  &.secondary-light {
    color: $light !important;
    background-color: transparent !important;
    border: solid 1px $light !important;

    &:hover {
      background-color: $secondaryColor !important;
      color: $light !important;
      border: solid 1px $light !important;
      text-decoration: underline !important;
    }

    &:focus {
      outline: 3px solid #95D5F5 !important;
    }

    &.is-clicked {
      background-color: $secondaryColor !important;
      color: $light !important;
      text-decoration: underline !important;
      border: 2px solid $light !important;
    }
  }

  &.secondary-light-2 {
    color: $light !important;
    background-color: transparent !important;
    border: solid 1px $light !important;

    &:hover {
      background-color: $primaryColor !important;
      color: $light !important;
      border: solid 1px $light !important;
      text-decoration: underline !important;
    }

    &:focus {
      outline: 3px solid #95D5F5 !important;
    }

    &.is-clicked {
      background-color: $primaryColor !important;
      color: $light !important;
      text-decoration: underline !important;
      border: 2px solid $light !important;
    }
  }
}

.link-no-effect {
  text-decoration: none !important;
  background: transparent !important;

  &:hover {
    text-decoration: none !important;
    background: transparent !important;
  }
}

a:not(.button):not(.link-no-effect) {
  &.light {
    color: $light !important;

    &:hover {
      color: $secondaryColor !important;
      background-color: $light !important;
    }

    &:focus {
      outline: 3px solid #95D5F5 !important;
    }

    svg {
      fill: currentColor;

      path {
        fill: currentColor;
      }
    }
  }
}