.blog-details {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 22px;

  @media screen and (min-width: 768px) {
    margin-top: 33px;
  }

  .container {
    width: 100%;
    max-width: calc(1175px + 2 * 40px);
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
      padding: 0 40px;
    }

    a {
      color: $secondaryColor;
    }
  }

  .text {
    color: #000;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 23px;

    @media screen and (min-width: 769px) {
      flex-direction: row;
    }
  }

  &__content {
    @media screen and (min-width: 769px) {
      width: 65.35%;
      flex-shrink: 0;
    }

    h1 {
      color: $secondaryColor;
      font-size: 32px;
      margin-bottom: 16px;
    }

    .wysiwyg {
      color: #000;
      margin-bottom: 60px;

      ul {
        padding-inline-start: 26px;
      }

      p, li {
        margin: 0 0 20px;
        line-height: 150%;
        font-size: 16px;
      }

      .blockquote p {
        margin: 0;
      }

      a {
        text-decoration: underline;
      }

      .wysiwyg__title {
        font-size: 20px;
        line-height: 140%;
        font-weight: 700;
      }

      .wysiwyg__subtitle {
        font-weight: 700;
        color: $secondaryColor;
      }
    }

    &-desc {
      margin-bottom: 16px;
      color: $tertiaryColor;
      font-size: 14px;
      display: flex;
      align-items: baseline;
      font-family: 'LiberationSans', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

      span:not(:last-of-type) {
        flex-shrink: 0;
      }

      @media screen and (min-width: 769px) {
        margin-bottom: 19px;
        text-align: left;
        font-size: 13px;
      }

      .point {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #71757E;
        margin: 0 4px 2px;
        transform: translateY(-2px);
      }
    }

    &-img {
      width: 100%;
      padding-bottom: 73%;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 50px;

      @media screen and (min-width: 769px) {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
      }
    }

    &-note {
      padding: 20px 0;
      border-bottom: 1px solid #D7D7D7;
      border-top: 1px solid #D7D7D7;

      a {
        text-decoration: underline;
      }
    }

    &-socials {
      padding: 20px 0;
      border-bottom: 1px solid #D7D7D7;

      p {
        font-size: 16px;
        font-weight: 700;
        color: $secondaryColor;
        margin-bottom: 20px;
      }

      a {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-also {
      padding: 20px 0;
      border-bottom: 1px solid #D7D7D7;

      @media screen and (min-width: 769px) {
        padding-bottom: 80px;
      }

      h3 {
        font-size: 24px;
        line-height: 150%;
        color: $secondaryColor;
        margin-bottom: 25px;
      }

      &>div {
        display: grid;
        gap: 26px;

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }

  .aside-blog {
    margin-top: 0px;
    @media screen and (min-width: 600px) {
      margin-top: 80px;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 24px;
      border-bottom: 1px solid #D7D7D7;
      margin-bottom: 20px;
    }
  }

  &__form {
    padding: 30px 0 70px;
    flex-grow: 1;

    @media screen and (min-width: 769px) {
      padding: 20px 0 120px;
    }

    h3 {
      font-size: 24px;
      line-height: 150%;
      color: $secondaryColor;
      margin-bottom: 36px;

      @media screen and (min-width: 769px) {
        margin-bottom: 20px;
      }
    }

    form {
      display: grid;
      gap: 16px;

      @media screen and (min-width: 769px) {
        width: 65.35%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 18px;
      }

      input, textarea {
        width: 100%;
        border: 1px solid $secondaryColor;
        border-radius: 24px;
        outline: none;
        padding: 14px 16px;
      }

      textarea {
        min-height: 190px;
        resize: vertical;

        @media screen and (min-width: 769px) {
          grid-column: span 2 / span 2;
          min-height: 126px;
        }
      }
    }
  }
}
