table {
  border-collapse: collapse;
  border-spacing: 0;

  td {
    padding: 10px 5px;
    text-align: left;
    vertical-align: top;
    border-bottom: 1px solid $tertiaryColor;
  }

  &.style-colored {
    td {
      border-right: 1px solid #dbe5f1;
      border-bottom: none;

      &:last-child {
        border-right: none;
      }
    }

    tr:nth-child(odd) {
      td {
        background-color: $bgLighterBlue;
      }
    }
  }
}
