
.blog-nav {
  font-family: Arial, Helvetica, sans-serif;

  &.details {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .container {
    width: 100%;
    max-width: calc(1175px + 2 * 40px);
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
      padding: 0 40px;
    }

    ul li {
      a {
        color: $light;
      }
    }
  }

  @media screen and (min-width: 768px) {
    ul {
      display: flex;
      padding-inline-start: 0;
    }

    ul li {
      list-style-type: none;
      min-width: 55px;
      flex-grow: 1;
      overflow: hidden;
      background: $secondaryColor;
      transition: all ease-in-out 0.3s;

      &:first-child {
        border-radius: 25px 0px 0px 25px;
      }

      &:last-child {
        border-radius: 0px 25px 25px 0px;
      }

      &:hover,
      &.active {
        background: $bgLighterBlue;

        a.navigation-link {
          color: $tertiaryColor;
        }
      }

      a.navigation-link {
        color: $light;
        font-weight: 700;
        font-size: 14px;
        line-height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  .itc-select__toggle {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .itc-select {
      position: relative;
      width: 100%;
    }

    .itc-select__toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 15px;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: $secondaryColor;
      background-color: $bgLighterBlue;
      border: none;
      border-radius: 33px;
      cursor: pointer;
      user-select: none;
      position: relative;

      &.selected {
        color: $tertiaryColor;

        &::after {
          background-image: url('data:image/svg+xml,%3Csvg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z" fill="%23002855"%3E%3C/path%3E%3C/svg%3E');
        }
      }

      &::after {
        flex-shrink: 0;
        width: 12px;
        height: 9px;
        background-image: url('data:image/svg+xml,%3Csvg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z" fill="%230047BB"%3E%3C/path%3E%3C/svg%3E');
        background-size: contain;
        content: "";
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:focus {
        outline: none;
      }
    }


    .itc-select_show .itc-select__toggle::after {
      transform: rotate(180deg);
      top: unset;
    }

    .itc-select_show .itc-select__toggle {
      border-radius: 20px 20px 0 0;
    }

    .itc-select__dropdown {
      position: absolute;
      top: 48px;
      right: 0;
      left: 0;
      z-index: 2;
      display: none;
      overflow-y: auto;
      background-color: $bgLighterBlue;
      border-radius: 0 0 20px 20px;
      padding: 0 15px;
    }

    .itc-select_show .itc-select__dropdown {
      display: block;
    }

    .itc-select_show .itc-select__backdrop {
      display: block;
    }

    .itc-select__options {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .itc-select__option {
      padding: 15px 0;
      text-align: center;
      border-top: 0.5px solid $blogGrey;

      a {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: $secondaryColor;
        display: inline-block;
        width: 100%;
      }
    }

    .itc-select__option:hover a {
      color: $tertiaryColor;
      cursor: pointer;
      transition: 0.2s color ease-in-out;
    }
    .itc-select__option_selected a {
      color: $tertiaryColor;
      cursor: pointer;
      transition: 0.2s color ease-in-out;
    }
  }
}
