.two-level-content {
   width: 100%;
   display: flex;
   justify-content: center;
 
   .content-wrapper {
     @extend .container;
     display: flex;
     flex-direction: column;
     gap: 40px;
 
     .content-row {
      display: flex;
      flex-direction: row;
      gap: 40px;
      @media screen and (max-width: 767px) {
         gap: 20px;
         flex-wrap: wrap;
      }

      .content-left {
         display: flex;
         flex-direction: column;
         font-size: 50px;
         line-height: 1.1;
         font-weight: bold;
         width: calc(50% - 20px);
         @media screen and (max-width: 1023px) {
            font-size: 44px;
            line-height: 1.1;
         }
         @media screen and (max-width: 767px) {
            font-size: 36px;
            line-height: 1.1;
            width: calc(50% - 10px);
         }
         @media screen and (max-width: 600px) {
            width: 100%;
         }
      }

      .content-right {
         display: flex;
         flex-direction: column;
         width: calc(50% - 20px);
         @media screen and (max-width: 767px) {
            width: calc(50% - 10px);
         }
         @media screen and (max-width: 600px) {
            width: 100%;
         }
      }

      .argument {
         display: flex;
         flex-direction: column;
         &-1 {
            margin: auto;
         }
         &-2 {
            width: 50%;
            @media screen and (max-width: 767px) {
               width: calc(50% - 10px);
            }
         }
         &-3 {
            width: calc( 33% - 20px );
            @media screen and (max-width: 767px) {
               width: calc(50% - 10px);
               margin: 0 auto;
            }
         }
         &-4 {
            width: 25%;
            @media screen and (max-width: 767px) {
               width: calc(50% - 10px);
            }
         }

         &-title {
            font-size: 61px;
            line-height: 1.43;
            @media screen and (max-width: 1023px) {
               font-size: 40px;
               line-height: 1.1;
            }
            @media screen and (max-width: 767px) {
               font-size: 30px;
               line-height: 1.1;
            }
         }
      }
     }
   }
 }
 