.layered-content {
   display: flex;
   justify-content: center;
   width: 100%;
   
   .content-wrapper {
      @extend .container;
      display: flex;
      flex-direction: column;

      .content-heading {
         width: 100%;
         margin-bottom: 40px;
   
         @media screen and (max-width: 767px) {
           margin-bottom: 20px;
         }
      }
   }
}

.layered-content-columns {
   display: flex;
   flex-direction: row;
   gap: 40px;
   width: 100%;

   @media screen and (max-width: 767px) {
      gap: 20px;
   }

   @media screen and (max-width: 600px) {
      flex-direction: column;
   }

   .layered-content-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.layered-content-column-reverse {
         flex-direction: column-reverse;
         justify-content: flex-end;

         .layered-content-text {
            margin: 130px 0 30px ;

            &.space-between-small {
               margin-top: 60px;
               margin-bottom: 30px;

               @media screen and (max-width: 1023px) {
                  margin-top: 40px;
               }
      
               @media screen and (max-width: 767px) {
                  margin-top: 30px;
               }

               @media screen and (max-width: 600px) {
                  margin: 20px 0;
               }
            }

            &.space-between-medium {
               margin-top: 90px;
               margin-bottom: 30px;

               @media screen and (max-width: 1023px) {
                  margin-top: 60px;
               }
      
               @media screen and (max-width: 767px) {
                  margin-top: 40px;
               }

               @media screen and (max-width: 600px) {
                  margin: 20px 0;
               }
            }

            &.space-between-large {
               margin-top: 130px;
               margin-bottom: 30px;

               @media screen and (max-width: 1023px) {
                  margin-top: 90px;
               }
      
               @media screen and (max-width: 767px) {
                  margin-top: 60px;
               }

               @media screen and (max-width: 600px) {
                  margin: 20px 0;
               }
            }
         }

         @media screen and (max-width: 600px) {
            flex-direction: column;
         }
      }

      .layered-content-text {
         margin: 30px 0;
         max-width: 490px;

         @media screen and (max-width: 600px) {
            max-width: none;
         }

         &.space-between-small {
            margin-bottom: 60px;

            @media screen and (max-width: 1023px) {
               margin-bottom: 40px;
            }
   
            @media screen and (max-width: 767px) {
               margin-bottom: 30px;
            }

            @media screen and (max-width: 600px) {
               margin: 20px 0;
            }
         }

         &.space-between-medium {
            margin-bottom: 90px;

            @media screen and (max-width: 1023px) {
               margin-bottom: 60px;
            }
   
            @media screen and (max-width: 767px) {
               margin-bottom: 40px;
            }

            @media screen and (max-width: 600px) {
               margin: 20px 0;
            }
         }

         &.space-between-large {
            margin-bottom: 130px;

            @media screen and (max-width: 1023px) {
               margin-bottom: 90px;
            }
   
            @media screen and (max-width: 767px) {
               margin-bottom: 60px;
            }

            @media screen and (max-width: 600px) {
               margin: 20px 0;
            }
         }
      }
      .layered-content-image-container {
         border-radius: 4px;
         overflow: hidden;
         width: 100%;

         img {
            display: block;
         }
      }
   }
}