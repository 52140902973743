.graph-and-content {
   width: 100%;
   display: flex;
   justify-content: center;
   overflow: hidden;

   .content-wrapper {
     @extend .container;
     @extend .padding-top-auto;
     @extend .padding-bottom-auto;
     display: flex;
     flex-direction: column;

      .content-heading {
         width: 100%;
         margin-bottom: 40px;

         @media screen and (max-width: 767px) {
         margin-bottom: 20px;
        }
      }

      .content-columns {
        display: flex;
        flex-direction: row;
        gap: 40px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          gap: 20px;
        }

        .container-image {
          display: flex;
          flex-direction: column;
          width: calc(50% - 20px);

          @media screen and (max-width: 767px) {
              width: 100%;
          }

          .graph-description {
            width: 100%;
            font-size: 14px;
            color: #93989e;
            position: relative;
            font-family: $fontArial;
            margin-top: 10px;
          }
        }

        .container-quote {
          max-width: calc(50% - 20px);
          width: 100%;

          @media screen and (max-width: 767px) {
              width: 100%;
              max-width: none;
          }
        }

        &.alignement-right {
          flex-direction: row-reverse;

          @media screen and (max-width: 767px) {
              flex-direction: column;
          }
        }
      }
   }
}
