.content-quote-arrow {
   width: 100%;
   display: flex;
   justify-content: center;

   .content-wrapper {
      @extend .container;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content-quote {
         position: relative;
         text-align: center;
         padding: 20px 40px;
         width: 100%;
         
         @media screen and (max-width: 600px) {
            min-width: auto;
            width: 100%;
         }

         &::after {
            content: "";
            position: absolute;
            bottom: -20px;
            left: var(--position);
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 24px 20px 0;
         }

         &.bg-color-auto {&::after {border-color: transparent $light transparent transparent;}}
         &.bg-color-blueDark {&::after {border-color: transparent $primaryColor transparent transparent;}}
         &.bg-color-blue {&::after {border-color: transparent $bgLightBlue transparent transparent;}}
         &.bg-color-grey {&::after {border-color: transparent $bgLightGrey transparent transparent;}}
         &.bg-color-dark {&::after {border-color: transparent $secondaryColor transparent transparent;}}

         .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            > p {
               margin: 0;
            }
         }
      }
   }
}