.content-triple-image {
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    @extend .container;
    @extend .padding-top-auto;
    @extend .padding-bottom-auto;
    display: flex;
    flex-direction: column;

    .content-heading {
      width: 100%;
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .content-columns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1023px) {
        gap: 40px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
      }

      .content-column {
        display: flex;
        flex-direction: column;
        width: calc(100% / 3);

        &.content-text {
          padding: 40px 0;
          @media screen and (max-width: 1023px) {
            width: calc(100% / 2);
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
          }
        }

        &.content-image {
          justify-content: flex-end;
          @media screen and (max-width: 1023px) {
            display: none;
          }

          .image-container {
            
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}
