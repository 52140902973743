// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

html {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: sans-serif;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  @extend .regularText;
  color: $tertiaryColor;
}

.fonts-loaded body {
  font-family: $fontArial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, Helvetica, sans-serif;
}

.fonts-loaded h1,
.fonts-loaded h2,
.fonts-loaded h3,
.fonts-loaded h4,
.fonts-loaded h5,
.fonts-loaded h6 {
  font-family: $fontMerkury;
}

p {
  margin: 0 0 10px;

  a {
    display: inline-block;
  }
}

a {
  color: $dark;
  text-decoration: none;

  &:focus {
    outline: 3px solid #009fdf !important;
  }
}

.content-blocks {
  a {
    &.bullet-link {
      display: list-item;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 40px;
      width: calc(100% - 40px);

      &::marker {
        color: $dark;
      }
    }

    &:not(.button):not(.link-no-effect) {
      text-decoration: underline;
      color: $secondaryColor;
      font-family: $fontMerkury;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $light !important;
        background: $secondaryColor;
      }

      &:focus {
        outline: 3px solid #009fdf;
      }

      &.disabled {
        pointer-events: none;
        color: #A2AAAD;
      }
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0 0 20px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c2c7d0;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #c2c7d0;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #c2c7d0;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #c2c7d0;
}

.hide-for-screen {
  font-size: 0;
}

/* -- For screen readers */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* -- A subtle focus style for keyboard-input elements */
.text-input:focus {
  outline: 1px solid #5db7a5; /* Adjust to suit your tastes */
}

/* -- No outline for non-keyboard-inputs elements */
select:focus {
  outline: none;
}

html.user-is-tabbing *:not(a):not(button):focus {
  outline: 2px solid var(--outlineColor) !important; /* for non-webkit browsers */
  outline: 5px auto -webkit-focus-ring-color !important;
}

/* -- Fontello */

.fonts-loaded [class^="icon-"]:before {
  font-family: "fontello";
}

.fonts-loaded [class*=" icon-"]:before {
  font-family: "fontello";
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

::-moz-selection {
  background: $primaryColor;
  color: #fff;
}
::selection {
  background: $primaryColor;
  color: #fff;
}

[v-cloak] {
  display: none;
}

.site-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .main {
    flex: 1;
  }
}

.custom-git-branch {
  position: fixed;
  display: flex;
  align-items: center;
  left: 20px;
  bottom: 20px;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: $primaryColor;
  color: #fff;
  z-index: 999999;
  pointer-events: none;

  & > svg {
    width: auto;
    margin-right: 5px;
  }
}

.container {
  width: 100%;
  max-width: calc(1175px + 2 * 40px);
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.bg-color-auto {
  background-color: $light;
}
.bg-color-blueDark {
  background-color: $primaryColor;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    color: $light;
  }

  // &:has(.button) {
  //   .button {
  //     @extend .primary-light;
  //   }

  //   .button.outlined {
  //     @extend .secondary-light;
  //   }
  // }

  a:not(.button):not(.link-no-effect) {
    text-decoration: underline;
    color: $light;
    font-family: $fontMerkury;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $secondaryColor;
      background: $light;
    }

    &:focus {
      outline: 3px solid #95D5F5;
    }

    &.disabled {
      pointer-events: none;
      color: #A2AAAD;
    }
  }
}
.bg-color-blue {
  background-color: $bgLightBlue;
}
.bg-color-grey {
  background-color: $bgLightGrey;
}
.bg-color-dark {
  background-color: $secondaryColor;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    color: $light;
  }

  // &:has(.button) {
  //   .button {
  //     @extend .primary-light;
  //   }

  //   .button.outlined {
  //     @extend .secondary-light-2;
  //   }
  // }

  a:not(.button):not(.link-no-effect) {
    text-decoration: underline;
    color: $light;
    font-family: $fontMerkury;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $secondaryColor;
      background: $light;
    }

    &:focus {
      outline: 3px solid #95D5F5;
    }

    &.disabled {
      pointer-events: none;
      color: #A2AAAD;
    }
  }
}

.has-separator {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: calc(100% - 80px);
    bottom: 0;
    left: 40px;
    height: 1px;
    background-color: $lightGrey;

    @media screen and (max-width: 767px) {
      width: calc(100% - 40px);
      left: 20px;
    }
  }
}

.padding-top-large {
  padding-top: 80px;

  @media screen and (max-width: 767px) {
    padding-top: 40px;
  }
}
.padding-top-auto {
  padding-top: 40px;

  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }
}
.padding-top-null {
  padding-top: 0;
}

.padding-bottom-large {
  padding-bottom: 80px;

  @media screen and (max-width: 767px) {
    padding-bottom: 40px;
  }
}
.padding-bottom-auto {
  padding-bottom: 40px;

  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
}
.padding-bottom-null {
  padding-bottom: 0;
}

.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}

.content-blocks {
  width: 100%;
  display: flex;
  flex-direction: column;

  &.has-hero-banner {
    margin-top: -80px;

    @media screen and (max-width: 767px) {
      margin-top: initial;
    }
  }
}

.content-column iframe {
  max-width: 100%;
}

#onetrust-banner-sdk.otRelFont,
#onetrust-pc-sdk.otRelFont {
  font-size: 16px !important;
}

.datalayer-outside {
  position: absolute;
  top: -5000px;
  left: -5000px;
}